import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import common from "../../common";
import user from "../user";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Services from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class ServicesSmart extends React.Component {
    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Services {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[user.constants.NAME].userData,
    services: state[constants.NAME].services,
    totalServicesCount: state[constants.NAME].totalServicesCount,
    servicesAddData: state[constants.NAME].servicesAddData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getServices: (isEmptyFirst, queryData, isRequestingForWhom) => dispatch(actions.getServices(isEmptyFirst, queryData, isRequestingForWhom)),
        addServices: (data) => dispatch(actions.addServices(data)),
        updateServicesStatus: (services_id, status, queryData) => dispatch(actions.updateServicesStatus(services_id, status, queryData)),
    }
};

export const ServicesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesSmart));
export default {actionTypes, actions, reducer, constants};