import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import common from "../../../common";
import user from "../../user";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import ServiceType from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class ServiceTypeSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <ServiceType {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[user.constants.NAME].userData,
    serviceTypes: state[constants.NAME].groups,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getServiceType: () => dispatch(actions.getServiceType()),
        addServiceType: (name, id) => dispatch(actions.addServiceType(name, id)),
        updateServiceTypeStatus: (id, status, name) => dispatch(actions.updateServiceTypeStatus(id, status, name)),
    }
};

export const ServiceTypeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceTypeSmart));
export default {actionTypes, actions, reducer, constants};