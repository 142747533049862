import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import common from "../../../common";
import city from "../../area/city";
import country from "../../area/country";
import statec from "../../area/state";
import user from "../../user";
import serviceTypes from "../serviceType";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Services from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class ServicesSmart extends React.Component {
    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Services {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[user.constants.NAME].userData,
    services: state[constants.NAME].services,
    totalServicesCount: state[constants.NAME].totalServicesCount,
    servicesAddData: state[constants.NAME].servicesAddData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    countries: HelperFunctions.getActiveForSelect(state[country.constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: HelperFunctions.getActiveForSelect(state[statec.constants.NAME].states, {name: "--Select State--", id: ''}),
    cities: HelperFunctions.getActiveForSelect(state[city.constants.NAME].cities, {name: "--Select City--", id: ''}),
    serviceTypes: HelperFunctions.getActiveForSelect(state[serviceTypes.constants.NAME].groups, {name: "--Select Service Type--", id: ''}),
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getServices: (service_type_id, isEmptyFirst, queryData, isRequestingForWhom) => dispatch(actions.getServices(service_type_id, isEmptyFirst, queryData, isRequestingForWhom)),
        addServices: (data) => dispatch(actions.addServices(data)),
        updateServicesStatus: (service_type_id, services_id, status, queryData) => dispatch(actions.updateServicesStatus(service_type_id, services_id, status, queryData)),
        getState: (country_id) => dispatch(statec.actions.getState(country_id)),
        getCity: (country_id, state_id) => dispatch(city.actions.getCity(country_id, state_id)),
    }
};

export const ServicesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesSmart));
export default {actionTypes, actions, reducer, constants};