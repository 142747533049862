import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import ServiceTypeAdd from "./add";
import ServiceTypeList from "./list";
import ServiceTypeUpdate from "./update";

export default class ServiceType extends React.Component {

    list = (props) => (
        this.props.userData.permissions.service_type_list ?
            <ServiceTypeList {...props} userData={this.props.userData} getServiceType={this.props.getServiceType} serviceTypes={this.props.serviceTypes} updateServiceTypeStatus={this.props.updateServiceTypeStatus}
                             isRequestingForWhom={this.props.isRequestingForWhom}/>
            : <ContentMessagePopup/>
    );
    add = (props) => (
        this.props.userData.permissions.service_type_add ?
            <ServiceTypeAdd {...props} addServiceType={this.props.addServiceType} isRequesting={this.props.isRequestingForWhom === 'user-services/service-type/add'}/>
            : <ContentMessagePopup/>
    );
    update = (props) => (
        this.props.userData.permissions.service_type_edit ?
            <ServiceTypeUpdate {...props} addServiceType={this.props.addServiceType} isRequesting={this.props.isRequestingForWhom === 'user-services/service-type/add'}/>
            : <ContentMessagePopup/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.userServices.serviceType.url} render={() => (
                    <Redirect to={ProjectRoutes.userServices.serviceType.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.userServices.serviceType.list.url} render={this.list}/>
                <Route path={ProjectRoutes.userServices.serviceType.add.url} render={this.add}/>
                <Route path={ProjectRoutes.userServices.serviceType.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
