import {faCartPlus, faEye, faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table, UncontrolledTooltip} from 'reactstrap';
import images from "../../../../../../assets/images";
import APIEndpoints from "../../../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import Constants from "../../../../../../utils/Constants";
import DateHelper from "../../../../../../utils/DateHelper";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";

export default class ShopList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                authorised_signatory_name: '',
                establishment_year: '',
                pin_code: '',
                country_id: '',
                state_id: '',
                city_id: '',
                user_id: APIEndpoints.appUser_User_ID ? APIEndpoints.appUser_User_ID : ((this.props.location.state && this.props.location.state.user_id) ? this.props.location.state.user_id : ''),
            },
            selectedGroupNumber: 0,
            currentPage: 1
        }
    }

    componentDidMount() {
        this.props.getShop('', true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
        window.userCollapse();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.shops && prevProps.shops !== this.props.shops && this.props.shops.length > 0) {
            window.userCollapse('orders-collapsible');
        }
    }

    componentWillUnmount() {
        window.userCollapseRemove('orders-collapsible');
    }

    groupChange = (event) => {
        this.props.getShop(this.props.groups[event.target.value].id, true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1});
        this.setState({selectedGroupNumber: event.target.value, currentPage: 1})
    };

    updateStatus = (shop_id, status) => {
        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
        this.props.updateShopStatus(g, shop_id, status, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage})
    };

    paginationClick = (pageNum) => {

        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';

        this.props.getShop(g, true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});

        this.setState({currentPage: pageNum});
    };

    renderPagination = () => {
        const {totalShopCount} = this.props;
        const {currentPage} = this.state;
        if (totalShopCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalShopCount / Constants.defaultPageSize);

            const pages = [];
            for (let i = 0; i < numPages; i++) {
                pages.push(<li key={"pg-" + i} className={`page-item mx-1 ${currentPage === (i + 1) ? "active" : ""}`} onClick={this.paginationClick.bind(this, (i + 1))}><a className="page-link py-1">{i + 1}</a></li>);
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="pt-5" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage - 1))} aria-label="Previous"><span
                            aria-hidden="true">«</span></a></li>
                        {pages}
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage + 1))} aria-label="Next"><span
                            aria-hidden="true">»</span></a></li>
                    </ul>
                </nav>
            );
        }
        else return null;
    };

    reset = () => {
        this.setState({
            data: {
                name: '',
                authorised_signatory_name: '',
                establishment_year: '',
                pin_code: '',
                country_id: '',
                state_id: '',
                city_id: '',
                user_id: ''
            }
        });
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "shop/list")) {
            const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
            this.props.getShop(g, true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1}, "shop/search");
            this.setState({currentPage: 1});
        }
    };

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    renderFilter = () => {
        const {isRequestingForWhom} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible py-2 text-muted">Looking for something? <span className="text-success">Click here</span> to refine your search.</button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form className="pt-2">
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input type="text" name="name" id="name" bsSize="sm" value={data["name"]}
                                           onChange={this.onInputChange.bind(this, "name")}/>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="user_id">User ID</Label>
                                    <Input disabled={APIEndpoints.appUser_User_ID} type="text" name="user_id" id="user_id" bsSize="sm" value={data["user_id"]}
                                           onChange={this.onInputChange.bind(this, "user_id")}/>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="establishment_year">Establishment Year</Label>
                                    <Input type="text" name="establishment_year" id="establishment_year" bsSize="sm" value={data["establishment_year"]}
                                           onChange={this.onInputChange.bind(this, "establishment_year")}/>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="pin_code">Pin Code</Label>
                                    <Input type="text" name="pin_code" id="pin_code" bsSize="sm" value={data["pin_code"]}
                                           onChange={this.onInputChange.bind(this, "pin_code")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "shop/search") && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "shop/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    clickView = (order_id) => {
        const btn = document.getElementById('btn' + order_id);
        if (btn)
            btn.click();
    };

    render() {
        const {groups, shops, isRequestingForWhom} = this.props;
        const shopsLength = shops ? shops.length : null;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Shop"
                    subheading="Available Shop for Shri Agrawal Samaj Pranyas."
                    icon="pe-7s-cart icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        this.props.userData.permissions.shop_add && <Link to={ProjectRoutes.sale.shop.add.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">Add Shop</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        Shop List
                                        <div className="btn-actions-pane-right">
                                            <Row form>
                                                <Col style={{minWidth: 180}}>
                                                    <Input type="select" name="groups" id="groups" bsSize="sm" className="mt-1"
                                                           onChange={this.groupChange}>
                                                        {groups && groups.length > 0 && groups.map((shop, index) => {
                                                            return (
                                                                <option key={"" + index}
                                                                        value={index}>{shop.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {this.renderFilter()}
                                    {shopsLength > 0 && (
                                        <React.Fragment>
                                            <Table responsive striped className="mb-0">
                                                <thead>
                                                <tr>
                                                    <th>Images</th>
                                                    <th>Name</th>
                                                    {/*<th>Est. Year</th>*/}
                                                    {/*<th>GST No.</th>*/}
                                                    {/*<th>Dealing Groups</th>*/}
                                                    {/*<th>Address</th>*/}
                                                    <th>Added Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {shops.map((shop, index) => {
                                                    const obj = {};
                                                    if (HelperFunctions.isActive(shop.status)) {
                                                        obj.icon = faThumbsDown;
                                                        obj.ttxt = "Make Inactive";
                                                        obj.color = "danger";
                                                        obj.statusToChange = "Inactive";
                                                    } else {
                                                        obj.icon = faThumbsUp;
                                                        obj.ttxt = "Make Active";
                                                        obj.color = "success";
                                                        obj.statusToChange = "Active";
                                                    }

                                                    const objEye = {};
                                                    objEye.icon = faEye;
                                                    objEye.ttxt = "View Shop";
                                                    objEye.color = "success";

                                                    const objShop = {};
                                                    objShop.icon = faCartPlus;
                                                    objShop.ttxt = "Add Product";
                                                    objShop.color = "warning";

                                                    return (
                                                        <React.Fragment key={"" + index}>
                                                            <tr key={"" + index}>
                                                                <td>
                                                                    <img className={"shop-list-img"} width={100} src={shop.image_url1 ? shop.base_url + shop.image_url1 : images.cameraPhoto} alt=""/>
                                                                </td>
                                                                <td>{shop.name}</td>
                                                                {/*<td>{shop.establishment_year}</td>*/}
                                                                {/*<td>{shop.gst_number}</td>*/}
                                                                {/*<td>
                                                                    <Input type="select" bsSize="sm" disabled
                                                                           value={shop.group_ids} multiple>
                                                                        {groups && groups.length > 0 && groups.map((item, index) => {
                                                                            return (
                                                                                <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                            )
                                                                        })}
                                                                    </Input>
                                                                </td>*/}
                                                                {/*<td>{shop.address + "-" + shop.pin_code + " " + shop.city_name + "-" + shop.state_name + ", " + shop.country_name}</td>*/}

                                                                <td>{DateHelper.getDisplayDateFromStamp(shop.added_date)}</td>
                                                                <td>
                                                                    <div className="row">
                                                                        {(this.props.userData.permissions.shop_edit) && (
                                                                            <Link to={{
                                                                                pathname: ProjectRoutes.sale.shop.update.url,
                                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                                state: {
                                                                                    shop: shop
                                                                                }
                                                                            }}>
                                                                                <Button className="btn-shadow mr-2 btn-sm mt-1" color="info">
                                                                                    <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                                </Button>
                                                                            </Link>
                                                                        )}

                                                                        {((this.props.userData.permissions.shop_active_inactive) && (APIEndpoints.appUser_User_ID ? false : true)) && (
                                                                            <Button id={"btn" + index}
                                                                                    className="btn-shadow mr-2 btn-sm mt-1" color={obj.color}
                                                                                    onClick={this.updateStatus.bind(this, shop.id, obj.statusToChange)}
                                                                            >
                                                                                <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                                <UncontrolledTooltip placement="left"
                                                                                                     target={"btn" + index}>
                                                                                    {obj.ttxt}
                                                                                </UncontrolledTooltip>
                                                                            </Button>
                                                                        )}

                                                                        <Button id={"btneye" + index} className="btn-shadow mr-2 mt-1 btn-sm" color={objEye.color} onClick={this.clickView.bind(this, shop.id)}>
                                                                            <FontAwesomeIcon icon={objEye.icon} size={"1x"}/>
                                                                            <UncontrolledTooltip placement="left" target={"btneye" + index}>
                                                                                {objEye.ttxt}
                                                                            </UncontrolledTooltip>
                                                                        </Button>

                                                                        {(APIEndpoints.appUser_User_ID ? shop.status === 'Active' : true) && (
                                                                            <Link to={{
                                                                                pathname: ProjectRoutes.sale.product.list.url,
                                                                                search: HelperFunctions.getQueryStringFromURL() === "" ? "?shop_id=" + shop.id : HelperFunctions.getQueryStringFromURL() + "&shop_id=" + shop.id,
                                                                            }}>
                                                                                <Button id={"btnadd" + index} className="btn-shadow mr-2 mt-1 btn-sm" color={objShop.color}>
                                                                                    <FontAwesomeIcon icon={objShop.icon} size={"1x"}/>
                                                                                    <UncontrolledTooltip placement="left" target={"btnadd" + index}>
                                                                                        {objShop.ttxt}
                                                                                    </UncontrolledTooltip>
                                                                                </Button>
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={4} className="p-0">
                                                                    <button id={'btn' + shop.id} className="collapsible orders-collapsible hidden"/>
                                                                    <div className="content text-small text-muted bg-light">

                                                                        <table className="table table-outer">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>Est. Year</th>
                                                                                <th>GST No.</th>
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                            <tr>
                                                                                <td>{shop.establishment_year}</td>
                                                                                <td>{shop.gst_number}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <strong>Authorised Signatory Name</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    {shop.authorised_signatory_name}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <strong>FB Link</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    {shop.fb_link}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <strong>Instagram Link</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    {shop.insta_link}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <strong>Dealing Groups</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <Input type="select" bsSize="sm" disabled
                                                                                           value={shop.group_ids} multiple>
                                                                                        {groups && groups.length > 0 && groups.map((item, index) => {
                                                                                            return (
                                                                                                <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                                            )
                                                                                        })}
                                                                                    </Input>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <strong>Shop Description</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2} dangerouslySetInnerHTML={{__html: shop.description}}>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <strong>Address details</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <p className="text-muted">
                                                                                        {shop.name},<br/>
                                                                                        {shop.address},<br/>
                                                                                        {shop.pin_code + " " + shop.city_name + "-" + shop.state_name + ", " + shop.country_name}
                                                                                    </p>
                                                                                </td>
                                                                            </tr>

                                                                            </tbody>


                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    )}
                                    {shopsLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No shop
                                            found for selected category!</div>
                                    )}
                                    {isRequestingForWhom === 'shop/list' && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {(shopsLength === null || isRequestingForWhom === "shop/update-status") &&
                                <div className="d-flex justify-content-center align-products-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
