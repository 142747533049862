import {faBuilding, faCheck, faList, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Form, FormGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Row, Table, UncontrolledTooltip} from 'reactstrap';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from "../../../../../utils/Constants";
import DateHelper from "../../../../../utils/DateHelper";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";

export default class CustomerList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                email: '',
                mobile: '',
                country_id: '',
                state_id: '',
                city_id: '',
            },
            currentPage: 1
        }
    }

    componentDidMount() {
        this.props.getCustomer({page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
        window.userCollapse();
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    countryChange = (event) => {
        this.onInputChange("country_id", event);
        this.props.getState(event.target.value);
    };

    stateChange = (event) => {
        this.onInputChange("state_id", event);
        this.props.getCity(this.state.data.country_id, event.target.value);
    };

    reset = () => {
        this.setState({
            data: {
                name: '',
                email: '',
                mobile: '',
                country_id: '',
                state_id: '',
                city_id: '',
            }
        });
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "customer/list")) {
            this.props.getCustomer({...this.state.data, page_size: Constants.defaultPageSize, page_number: 1}, "customer/search");

            this.setState({currentPage: 1});
        }
    };

    paginationClick = (pageNum) => {

        this.props.getCustomer({...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});

        this.setState({currentPage: pageNum});
    };

    renderFilter = () => {
        const {isRequestingForWhom, countries, states, cities} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible py-2 text-muted">Looking for something? <span className="text-success">Click here</span> to refine your search.</button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form className="pt-2">
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input type="text" name="name" id="name" bsSize="sm" value={data["name"]}
                                           onChange={this.onInputChange.bind(this, "name")}/>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="text" name="email" id="email" bsSize="sm" value={data["email"]}
                                           onChange={this.onInputChange.bind(this, "email")}/>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="mobile">Mobile</Label>
                                    <Input type="text" name="mobile" id="mobile" bsSize="sm" value={data["mobile"]}
                                           onChange={this.onInputChange.bind(this, "mobile")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="country_id">Select Country</Label>
                                    <Input type="select" bsSize="sm" name="country_id" id="country_id" value={data["country_id"]} onChange={this.countryChange}>
                                        {countries && countries.length > 0 && countries.map((item, index) => {
                                            return (
                                                <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="state_id">Select State</Label>
                                    <Input type="select" bsSize="sm" name="state_id" id="state_id" value={data["state_id"]} onChange={this.stateChange}>
                                        {states && states.length > 0 && states.map((item, index) => {
                                            return (
                                                <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                            )
                                        })}
                                    </Input>
                                    {isRequestingForWhom === 'state/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                        <div className="spinner-border spinner-border-sm text-primary mt-4" role="status"/>
                                    </div>}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="city_id">Select City</Label>
                                    <Input type="select" bsSize="sm" name="city_id" id="city_id" value={data["city_id"]} onChange={this.onInputChange.bind(this, 'city_id')}>
                                        {cities && cities.length > 0 && cities.map((item, index) => {
                                            return (
                                                <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                            )
                                        })}
                                    </Input>
                                    {isRequestingForWhom === 'city/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                        <div className="spinner-border spinner-border-sm text-primary mt-4" role="status"/>
                                    </div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "customer/search") && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "customer/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    renderPagination = () => {
        const {totalCustomerCount} = this.props;
        const {currentPage} = this.state;
        if (totalCustomerCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalCustomerCount / Constants.defaultPageSize);

            const pages = [];
            for (let i = 0; i < numPages; i++) {
                pages.push(<li key={"pg-" + i} className={`page-item mx-1 ${currentPage === (i + 1) ? "active" : ""}`} onClick={this.paginationClick.bind(this, (i + 1))}><a className="page-link py-1">{i + 1}</a></li>);
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="pt-5" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage - 1))} aria-label="Previous"><span
                            aria-hidden="true">«</span></a></li>
                        {pages}
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage + 1))} aria-label="Next"><span
                            aria-hidden="true">»</span></a></li>
                    </ul>
                </nav>
            );
        }
        else return null;
    };

    render() {
        const {customers, isRequestingForWhom, updateCustomerStatus} = this.props;

        const customersLength = customers ? customers.length : null;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas App Users"
                    subheading="Available App Users for Shri Agrawal Samaj Pranyas."
                    icon="pe-7s-users icon-gradient bg-happy-itmeo"
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>App Users List</CardTitle>
                                    {this.renderFilter()}

                                    {customersLength > 0 && (
                                        <React.Fragment>
                                            <Table responsive striped className="mb-0">
                                                <thead>
                                                <tr>
                                                    <th>User ID</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Added Date</th>
                                                    <th>Country</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>Postal Code</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {customers.map((item, index) => {
                                                    const obj = {};
                                                    if (HelperFunctions.isActive(item.status)) {
                                                        obj.icon = faThumbsDown;
                                                        obj.ttxt = "Make Inactive";
                                                        obj.color = "danger";
                                                        obj.statusToChange = "Inactive";
                                                    } else {
                                                        obj.icon = faThumbsUp;
                                                        obj.ttxt = "Make Active";
                                                        obj.color = "success";
                                                        obj.statusToChange = "Active";
                                                    }
                                                    return (
                                                        <tr key={"" + index}>
                                                            <td>{item.user_id}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.email + ' '}{item.is_email_verified && <FontAwesomeIcon icon={faCheck} size={"1x"} color={'#3ac47d'}/>}</td>
                                                            <td>{item.mobile}</td>
                                                            <td>{DateHelper.getDisplayDateFromStamp(item.added_date)}</td>
                                                            <td>{item.address && item.address.country_name}</td>
                                                            <td>{item.address && item.address.state_name}</td>
                                                            <td>{item.address && item.address.city_name}</td>
                                                            <td>{item.address && item.address.pin_code}</td>
                                                            <td>
                                                                <div className="row">
                                                                    {this.props.userData.permissions.app_user_active_inactive && (
                                                                        <Button id={"btn" + index} className="btn-shadow mr-2 btn-sm" color={obj.color}
                                                                                onClick={updateCustomerStatus.bind(this, item.user_id, obj.statusToChange, {
                                                                                    ...this.state.data,
                                                                                    page_size: Constants.defaultPageSize,
                                                                                    page_number: this.state.currentPage
                                                                                })}>
                                                                            <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                            <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                                {obj.ttxt}
                                                                            </UncontrolledTooltip>
                                                                        </Button>
                                                                    )}
                                                                    <Link to={{
                                                                        pathname: ProjectRoutes.sale.shop.add.url,
                                                                        search: HelperFunctions.getQueryStringFromURL(),
                                                                        state: {
                                                                            user_id: item.user_id
                                                                        }
                                                                    }}>
                                                                        <Button id={"btnshop" + index} className="btn-shadow mr-2 btn-sm" color={"info"}>
                                                                            <FontAwesomeIcon icon={faBuilding} size={"1x"}/>
                                                                            <UncontrolledTooltip placement="left" target={"btnshop" + index}>
                                                                                Add Shop
                                                                            </UncontrolledTooltip>
                                                                        </Button>
                                                                    </Link>
                                                                    <Link to={{
                                                                        pathname: ProjectRoutes.sale.shop.list.url,
                                                                        search: HelperFunctions.getQueryStringFromURL(),
                                                                        state: {
                                                                            user_id: item.user_id
                                                                        }
                                                                    }}>
                                                                        <Button id={"btnshop1" + index} className="btn-shadow mr-2 btn-sm" color={"secondary"}>
                                                                            <FontAwesomeIcon icon={faList} size={"1x"}/>
                                                                            <UncontrolledTooltip placement="left" target={"btnshop1" + index}>
                                                                                List Shops
                                                                            </UncontrolledTooltip>
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    )}
                                </CardBody>
                                {customersLength === 0 && (
                                    <div className="page-title-subheading d-flex justify-content-center py-5">No App Users
                                        found for selected filters!</div>
                                )}
                                {customersLength === null && (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                        </div>
                                    </div>
                                )}
                                {isRequestingForWhom === "customer/update-status" &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
