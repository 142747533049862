import {faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Input, Row, Table, UncontrolledTooltip} from 'reactstrap';
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";

export default class StateList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCountryNumber: 0
        }
    }

    componentDidMount() {
        this.props.getState('');
    }

    change = (event) => {
        this.setState({selectedCountryNumber: event.target.value})
        this.props.getState(this.props.countries[event.target.value].id);
    }

    render() {
        const {countries, states, isRequestingForWhom, updateStateStatus} = this.props;
        const statesLength = states ? states.length : null;
        const {selectedCountryNumber} = this.state;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas State"
                    subheading="Available State for Shri Agrawal Samaj Pranyas."
                    icon="pe-7s-map-marker icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        this.props.userData.permissions.area_add && (
                            <Link to={ProjectRoutes.area.state.add.url + HelperFunctions.getQueryStringFromURL()}>
                                <Button className="btn-shadow mr-3" color="dark">Add State</Button>
                            </Link>
                        )
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        State List
                                        <div className="btn-actions-pane-right">
                                            <Input type="select" name="country" id="country" bsSize="sm" onChange={this.change}>
                                                {countries && countries.length > 0 && countries.map((item, index) => {
                                                    return (
                                                        <option key={"" + index} value={index}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </div>
                                    </div>
                                    {statesLength > 0 && (
                                        <Table responsive striped className="mb-0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {states.map((item, index) => {
                                                const obj = {};
                                                if (HelperFunctions.isActive(item.status)) {
                                                    obj.icon = faThumbsDown;
                                                    obj.ttxt = "Make Inactive";
                                                    obj.color = "danger";
                                                    obj.statusToChange = "Inactive";
                                                } else {
                                                    obj.icon = faThumbsUp;
                                                    obj.ttxt = "Make Active";
                                                    obj.color = "success";
                                                    obj.statusToChange = "Active";
                                                }
                                                return (
                                                    <tr key={"" + index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            {this.props.userData.permissions.area_edit && (
                                                                <Link to={{
                                                                    pathname: ProjectRoutes.area.state.update.url,
                                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                                    state: {id: item.id, country: this.props.countries[selectedCountryNumber], name: item.name}
                                                                }}>
                                                                    <Button className="btn-shadow mr-2 btn-sm" color="info">
                                                                        <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                    </Button>
                                                                </Link>
                                                            )}

                                                            {this.props.userData.permissions.area_active_inactive && (
                                                                <Button id={"btn" + index} className="btn-shadow mr-2 btn-sm" color={obj.color}
                                                                        onClick={updateStateStatus.bind(this, this.props.countries[selectedCountryNumber].id, item.id, obj.statusToChange)}>
                                                                    <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                    <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                        {obj.ttxt}
                                                                    </UncontrolledTooltip>
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    )}
                                    {statesLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No State found for selected country!</div>
                                    )}
                                    {isRequestingForWhom === 'state/list' && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "state/update-status" &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
