import {faRupeeSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from 'reactstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import images from "../../../../../../assets/images";
import APIEndpoints from "../../../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";
import ImageCrop from "./ImageCrop";
import "./index.css";

export default class ProductAdd extends React.Component {

    constructor(props) {
        super(props);

        this.isUpdate = (this.props.location.state && this.props.location.state.product) ? true : false;
        const obj = this.isUpdate ? this.props.location.state.product : {};

        this.state = {
            data: {
                group_id: '',
                category_id: '',
                shop_id: HelperFunctions.getQueryStringValue("shop_id"),
                name: '',
                rent_per_product: '',
                min_order_quantity: 1,
                color: '',
                fabric: '',
                description: '',
                length: '',
                width: '',
                total_quantity: '',
                discount: 0,
                ...obj,
                image_url1: this.isUpdate ? obj.image_url1 ? obj.image_url1 : '' : '',
                image_url2: this.isUpdate ? obj.image_url2 ? obj.image_url2 : '' : '',
                image_url3: this.isUpdate ? obj.image_url3 ? obj.image_url3 : '' : '',
                image_url4: this.isUpdate ? obj.image_url4 ? obj.image_url4 : '' : '',
                image_url5: this.isUpdate ? obj.image_url5 ? obj.image_url5 : '' : '',
                image_url6: this.isUpdate ? obj.image_url6 ? obj.image_url6 : '' : '',
            },
            file1: '',
            file2: '',
            file3: '',
            file4: '',
            file5: '',
            file6: '',
            selectedImageNumber: null,
        }
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });

        switch (key) {
            case "group_id":
                this.props.getCategory(event.target.value);
                break;
        }
    };

    handleEditorChange = (content, editor) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy['description'] = content;
        this.setState({
            data: dataCopy
        });
    };

    componentDidMount() {
        if (this.isUpdate) {
            this.props.getCategory(this.state.data.group_id);
        }
    }

    add = () => {
        if (!this.props.isRequesting) {
            const data = new FormData();
            for (const key in this.state.data) {
                data.append(key, this.state.data[key]);
            }

            data.append("file1", this.state.file1);
            data.append("file2", this.state.file2);
            data.append("file3", this.state.file3);
            data.append("file4", this.state.file4);
            data.append("file5", this.state.file5);
            data.append("file6", this.state.file6);

            this.props.addProduct(data);
        }
    };

    selectFile = (selectedFileInput) => {
        this.setState({selectedImageNumber: selectedFileInput})
    };

    hideImageCrop = () => {
        this.setState({selectedImageNumber: null});
    };

    getCroppedImage = (croppedFileObj) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy["image_url" + this.state.selectedImageNumber] = croppedFileObj.croppedImageUrl;

        switch (this.state.selectedImageNumber) {
            case 1:
                this.setState({
                    file1: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 2:
                this.setState({
                    file2: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 3:
                this.setState({
                    file3: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 4:
                this.setState({
                    file4: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 5:
                this.setState({
                    file5: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 6:
                this.setState({
                    file6: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
        }
    };

    returnImagePath = (imagePath) => {
        if (imagePath) {
            return imagePath.includes("?v=") ? this.props.location.state.product.base_url + imagePath : imagePath;
        }
        return images.cameraPhoto;
    };

    render() {
        const {groups, shops, categories, isRequesting, productAddData} = this.props;
        const {data, selectedImageNumber} = this.state;
        const errors = productAddData && productAddData.errors ? productAddData.errors : {};

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Product"
                    subheading={this.isUpdate ? "Update a product for Shri Agrawal Samaj Pranyas business." : "Add a new product for Shri Agrawal Samaj Pranyas business."}
                    icon="pe-7s-cart icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.product.list.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">List Product</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>{this.isUpdate ? 'Update Product' : 'Add Product'}</CardTitle>
                                    <Row form>
                                        <Col md="1" className={"row-image-container border"}>
                                            <div className={"left border-right"}>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 1)}>
                                                    <img src={this.returnImagePath(data.image_url1)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 2)}>
                                                    <img src={this.returnImagePath(data.image_url2)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 3)}>
                                                    <img src={this.returnImagePath(data.image_url3)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 4)}>
                                                    <img src={this.returnImagePath(data.image_url4)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 5)}>
                                                    <img src={this.returnImagePath(data.image_url5)} alt=""/>
                                                </div>
                                                <div className={"item"} onClick={this.selectFile.bind(this, 6)}>
                                                    <img src={this.returnImagePath(data.image_url6)} alt=""/>
                                                </div>

                                                {selectedImageNumber && <ImageCrop getCroppedImage={this.getCroppedImage} hideImageCrop={this.hideImageCrop}/>}
                                            </div>
                                        </Col>
                                        <Col lg="11" md={{size: 11, offset: 0}}>
                                            <Form>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="category_id">Select Group</Label>
                                                            <Input type="select" name="group_id" id="group_id" bsSize="sm"
                                                                   invalid={HelperFunctions.isNotEmpty(errors["group_id"])}
                                                                   onChange={this.onInputChange.bind(this, "group_id")} value={data.group_id}>
                                                                {groups && groups.length > 0 && groups.map((item, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Oh noes! pick a correct category for product</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="group_id">Select Category</Label>
                                                            <Input type="select" name="category_id" id="category_id" bsSize="sm"
                                                                   invalid={HelperFunctions.isNotEmpty(errors["category_id"])}
                                                                   onChange={this.onInputChange.bind(this, "category_id")} value={data.category_id}>
                                                                {categories && categories.length > 0 && categories.map((product, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={product.id ? product.id : ''}>{product.name}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Oh noes! pick a correct group for product</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="shop_id">Select Shop</Label>
                                                            <Input disabled={APIEndpoints.appUser_User_ID} type="select" name="shop_id" id="shop_id" bsSize="sm"
                                                                   invalid={HelperFunctions.isNotEmpty(errors["shop_id"])}
                                                                   onChange={this.onInputChange.bind(this, "shop_id")} value={data.shop_id}>
                                                                {shops && shops.length > 0 && shops.map((item, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name + " - " + item.id}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Oh noes! pick a correct shop for product</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="name">Name</Label>
                                                            <Input type="text" name="name" id="name" bsSize="sm" placeholder="" value={data["name"]}
                                                                   invalid={HelperFunctions.isNotEmpty(errors["name"])}
                                                                   onChange={this.onInputChange.bind(this, "name")}/>
                                                            <FormFeedback>Oh noes! pick a correct name for product</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="rentPerProduct">Sale per product</Label>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <FontAwesomeIcon
                                                                            icon={faRupeeSign}/></InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type="text" name="rent_per_product" id="rent_per_product" bsSize="sm" placeholder="" value={data["rent_per_product"]}
                                                                       invalid={HelperFunctions.isNotEmpty(errors["rent_per_product"])}
                                                                       onChange={this.onInputChange.bind(this, "rent_per_product")}/>
                                                                <FormFeedback>Oh noes! pick a correct price</FormFeedback>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="min_order_quantity">Min. Order Quantity</Label>
                                                            <Input type="number" name="min_order_quantity" bsSize="sm" id="min_order_quantity" placeholder="" value={data["min_order_quantity"]}
                                                                   invalid={HelperFunctions.isNotEmpty(errors["min_order_quantity"])}
                                                                   onChange={this.onInputChange.bind(this, "min_order_quantity")}/>
                                                            <FormFeedback>Oh noes! pick a correct min</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="total_quantity">Total Stock Quantity</Label>
                                                            <Input type="number" name="total_quantity" id="total_quantity" bsSize="sm" placeholder="" value={data["total_quantity"]}
                                                                   onChange={this.onInputChange.bind(this, "total_quantity")}/>
                                                            <FormFeedback>Oh noes! pick a correct total quantity</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="color">Colors Available(Comma seprated)</Label>
                                                            <Input type="text" name="color" id="color" bsSize="sm" placeholder="Red,Green" value={data["color"]}
                                                                   onChange={this.onInputChange.bind(this, "color")}/>
                                                            <FormFeedback>Oh noes! pick a correct color</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="fabric">Fabric/Metarial(If any)</Label>
                                                            <Input type="text" name="fabric" id="fabric" bsSize="sm" placeholder="" value={data["fabric"]}
                                                                   onChange={this.onInputChange.bind(this, "fabric")}/>
                                                            <FormFeedback>Oh noes! pick a correct fabric</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="length">Length</Label>
                                                            <Input type="text" name="length" id="length" bsSize="sm" placeholder="" value={data["length"]}
                                                                   onChange={this.onInputChange.bind(this, "length")}/>
                                                            <FormFeedback>Oh noes! pick a correct length</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="width">Width</Label>
                                                            <Input type="text" name="width" id="width" bsSize="sm" placeholder="" value={data["width"]}
                                                                   onChange={this.onInputChange.bind(this, "width")}/>
                                                            <FormFeedback>Oh noes! pick a correct width</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="discount">Discount</Label>
                                                            <Input type="number" name="discount" id="discount" bsSize="sm" placeholder=""
                                                                   value={data["discount"]}
                                                                   onChange={this.onInputChange.bind(this, "discount")}/>
                                                            <FormFeedback>Oh noes! pick a correct transportation vehicle</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="12">
                                                        <FormGroup className={"mb-0"}>
                                                            <Label for="description">Description</Label>
                                                            {/*<Input type="textarea" name="description" id="description" bsSize="sm" placeholder="" value={data["description"]}
                                                                   onChange={this.onInputChange.bind(this, "description")} style={{resize: 'none'}}/>*/}
                                                            {/*<Editor
                                                                value={data["description"]}
                                                                name="description" id="description" bsSize="sm" placeholder=""
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false
                                                                }}
                                                                style={{resize: 'none'}}
                                                                onEditorChange={this.handleEditorChange}
                                                            />*/}
                                                            <SunEditor name="description" id="description" bsSize="sm"
                                                                       placeholder="Please type here..." height={300} style={{resize: 'none'}}
                                                                       defaultValue={data["description"]}
                                                                       onChange={this.handleEditorChange}/>
                                                            <FormFeedback>Oh noes! pick a correct description</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className={"justify-content-center mt-2"}>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting &&
                                            <span className="spinner-grow spinner-grow-sm mr-1"
                                                  role="status"
                                                  aria-hidden="true"/>}
                                            {isRequesting ? this.isUpdate ? "Updating...." : "Adding..." : this.isUpdate ? "Update" : "Add"}
                                        </Button>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
