import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../../config/APIEndpoints";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {

    }
}

export function getServiceType(isEmpty = true) {
    return (dispatch, getState) => {
        let payloadObj = {};
        if (isEmpty) {
            payloadObj = {isRequestingForWhom: "user-services/service-type/list"};
        }
        dispatch({
            type: ActionTypes.GET_SERVICETYPE,
            payload: payloadObj,
            promise: APIEndpoints.getServiceType(),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addServiceType(name = '', id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_SERVICETYPE,
            payload: {isRequestingForWhom: "user-services/service-type/add"},
            promise: APIEndpoints.addServiceType({name, id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateServiceTypeStatus(id = '', status = '', name = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_SERVICETYPE_STATUS,
            payload: {isRequestingForWhom: "user-services/service-type/update-status"},
            promise: APIEndpoints.updateServiceTypeStatus({id, status, name}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getServiceType());
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
