import * as ActionTypes from "./actionTypes";

const initialState = {
    shops: null,
    shopAddData: {},
    totalShopCount: 0
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_SHOP:
            console.log(action.payload);
            return {...state, ...action.payload};
        case ActionTypes.ADD_SHOP:
        case ActionTypes.GET_SHOP_ONE:
            return {...state, shopAddData: action.payload};
        default:
            return {...state}
    }
};
export default reducer;