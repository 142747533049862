import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import common from "../../../common";
import user from "../../user";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Group from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class GroupSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Group {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[user.constants.NAME].userData,
    groups: state[constants.NAME].groups,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getGroup: () => dispatch(actions.getGroup()),
        addGroup: (name, id) => dispatch(actions.addGroup(name, id)),
        updateGroupStatus: (id, status, name) => dispatch(actions.updateGroupStatus(id, status, name)),
    }
};

export const GroupContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupSmart));
export default {actionTypes, actions, reducer, constants};