import {NAME} from "./constants";
export const GET_EMPLOYEE = `${NAME}/GET_EMPLOYEE`;
export const GET_EMPLOYEE_ONE = `${NAME}/GET_EMPLOYEE_ONE`;
export const UPDATE_EMPLOYEE_ADDRESS = `${NAME}/UPDATE_EMPLOYEE_ADDRESS`;
export const UPDATE_EMPLOYEE_PERMISSSIONS = `${NAME}/UPDATE_EMPLOYEE_PERMISSSIONS`;
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT = `${NAME}/UPDATE_EMPLOYEE_EMERGENCY_CONTACT`;
export const UPDATE_EMPLOYEE_IMAGE = `${NAME}/UPDATE_EMPLOYEE_IMAGE`;
export const UPDATE_EMPLOYEE_DOCUMENTS = `${NAME}/UPDATE_EMPLOYEE_DOCUMENTS`;
export const ADD_EMPLOYEE_BASIC_INFO = `${NAME}/ADD_EMPLOYEE_BASIC_INFO`;
export const UPDATE_EMPLOYEE_STATUS = `${NAME}/UPDATE_EMPLOYEE_STATUS`;
export const CHANGE_TAB = `${NAME}/CHANGE_TAB`;