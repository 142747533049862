import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faMapMarked} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from 'reactstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import images from "../../../../../../assets/images";
import APIEndpoints from "../../../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";
import ImageCrop from "./ImageCrop";
import "./index.css";

export default class ShopAdd extends React.Component {

    constructor(props) {
        super(props);

        this.isUpdate = (this.props.location.state && this.props.location.state.shop) ? true : false;
        const obj = this.isUpdate ? this.props.location.state.shop : {};
        if (this.isUpdate) {
            obj.group_id = obj.group_ids ? obj.group_ids : [];
        }

        this.state = {
            data: {
                group_id: [],
                name: '',
                authorised_signatory_name: '',
                landmark: '',
                establishment_year: '',
                working_days_hours: '',
                description: '',
                gst_number: '',
                address: '',
                pin_code: '',
                country_id: '',
                country_name: '',
                state_id: '',
                state_name: '',
                city_id: '',
                city_name: '',
                fb_link: '',
                insta_link: '',
                user_id: APIEndpoints.appUser_User_ID ? APIEndpoints.appUser_User_ID : ((this.props.location.state && this.props.location.state.user_id) ? this.props.location.state.user_id : ''),
                ...obj,
                image_url1: this.isUpdate ? obj.image_url1 ? obj.image_url1 : '' : '',
                image_url2: this.isUpdate ? obj.image_url2 ? obj.image_url2 : '' : '',
                image_url3: this.isUpdate ? obj.image_url3 ? obj.image_url3 : '' : '',
                image_url4: this.isUpdate ? obj.image_url4 ? obj.image_url4 : '' : '',
                image_url5: this.isUpdate ? obj.image_url5 ? obj.image_url5 : '' : '',
                image_url6: this.isUpdate ? obj.image_url6 ? obj.image_url6 : '' : '',
            },
            file1: '',
            file2: '',
            file3: '',
            file4: '',
            file5: '',
            file6: '',
            selectedImageNumber: null,
        };

        // call default state and cities
        if (obj.country_id) {
            this.props.getState(obj.country_id);
            if (obj.state_id) {
                this.props.getCity(obj.country_id, obj.state_id);
            }
        }
    }

    countryChange = (event) => {
        if (event.target.value) {

            let country_name = '';
            for (let i = 0; i < this.props.countries.length; i++) {
                if (event.target.value === this.props.countries[i].id) {
                    country_name = this.props.countries[i].name;
                    break;
                }
            }

            // update state_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['country_name'] = country_name;
            dataCopy['country_id'] = event.target.value;
            this.setState({
                data: dataCopy
            });

            this.props.getState(event.target.value);
        }
    };

    stateChange = (event) => {
        if (event.target.value) {

            let state_name = '';
            for (let i = 0; i < this.props.states.length; i++) {
                if (event.target.value === this.props.states[i].id) {
                    state_name = this.props.states[i].name;
                    break;
                }
            }

            // update state_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['state_name'] = state_name;
            dataCopy['state_id'] = event.target.value;
            this.setState({
                data: dataCopy
            });

            this.props.getCity(this.state.data.country_id, event.target.value);
        }
    };

    cityChange = (event) => {
        if (event.target.value) {
            let city_name = '';
            for (let i = 0; i < this.props.cities.length; i++) {
                if (event.target.value === this.props.cities[i].id) {
                    city_name = this.props.cities[i].name;
                    break;
                }
            }

            // update city_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['city_name'] = city_name;
            dataCopy['city_id'] = event.target.value;
            this.setState({
                data: dataCopy
            });
        }
    };

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };
    onGroupChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = Array.from(event.target.selectedOptions, (item) => item.value);
        this.setState({
            data: dataCopy
        });
    };

    handleEditorChange = (content, editor) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy['description'] = content;
        this.setState({
            data: dataCopy
        });
    };

    componentDidMount() {
        if (this.isUpdate) {

        }
    }

    add = () => {
        if (!this.props.isRequesting) {
            const data = new FormData();
            for (const key in this.state.data) {
                data.append(key, this.state.data[key]);
            }

            data.append("file1", this.state.file1);
            data.append("file2", this.state.file2);
            data.append("file3", this.state.file3);
            data.append("file4", this.state.file4);
            data.append("file5", this.state.file5);
            data.append("file6", this.state.file6);

            this.props.addShop(data);
        }
    };

    selectFile = (selectedFileInput) => {
        this.setState({selectedImageNumber: selectedFileInput})
    };

    hideImageCrop = () => {
        this.setState({selectedImageNumber: null});
    };

    getCroppedImage = (croppedFileObj) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy["image_url" + this.state.selectedImageNumber] = croppedFileObj.croppedImageUrl;

        switch (this.state.selectedImageNumber) {
            case 1:
                this.setState({
                    file1: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 2:
                this.setState({
                    file2: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 3:
                this.setState({
                    file3: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 4:
                this.setState({
                    file4: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 5:
                this.setState({
                    file5: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 6:
                this.setState({
                    file6: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
        }
    };

    returnImagePath = (imagePath) => {
        if (imagePath) {
            return imagePath.includes("?v=") ? this.props.location.state.shop.base_url + imagePath : imagePath;
        }
        return images.cameraPhoto;
    };

    render() {
        const {isRequestingForWhom, groups, shopTypes, categories, isRequesting, shopAddData, countries, states, cities} = this.props;
        const {data, selectedImageNumber} = this.state;
        const errors = shopAddData && shopAddData.errors ? shopAddData.errors : {};

        const estaYears = HelperFunctions.getYears();

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Shop"
                    subheading={this.isUpdate ? "Update a shop for Shri Agrawal Samaj Pranyas." : "Add a new shop for Shri Agrawal Samaj Pranyas."}
                    icon="pe-7s-box2 icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.shop.list.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">List Shop</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>{this.isUpdate ? 'Update Shop' : 'Add Shop'}</CardTitle>
                                    <Row form>
                                        <Col md="1" className={"row-image-container border"}>
                                            <div className={"left border-right"}>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 1)}>
                                                    <img src={this.returnImagePath(data.image_url1)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 2)}>
                                                    <img src={this.returnImagePath(data.image_url2)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 3)}>
                                                    <img src={this.returnImagePath(data.image_url3)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 4)}>
                                                    <img src={this.returnImagePath(data.image_url4)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 5)}>
                                                    <img src={this.returnImagePath(data.image_url5)} alt=""/>
                                                </div>
                                                <div className={"item"} onClick={this.selectFile.bind(this, 6)}>
                                                    <img src={this.returnImagePath(data.image_url6)} alt=""/>
                                                </div>

                                                {selectedImageNumber && <ImageCrop getCroppedImage={this.getCroppedImage} hideImageCrop={this.hideImageCrop}/>}
                                            </div>
                                        </Col>
                                        <Col lg="11" md={{size: 11, offset: 0}}>
                                            <Form>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="name">Name of the establishment</Label>
                                                            <Input type="text" name="name" id="name" bsSize="sm" placeholder="" value={data["name"]}
                                                                   invalid={HelperFunctions.isNotEmpty(errors["name"])}
                                                                   onChange={this.onInputChange.bind(this, "name")}/>
                                                            <FormFeedback>Oh noes! pick a correct name for shop</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="authorised_signatory_name">Authorised Signatory Name</Label>
                                                            <Input type="text" name="authorised_signatory_name" id="authorised_signatory_name" bsSize="sm" placeholder="" value={data["authorised_signatory_name"]}
                                                                   invalid={HelperFunctions.isNotEmpty(errors["authorised_signatory_name"])}
                                                                   onChange={this.onInputChange.bind(this, "authorised_signatory_name")}/>
                                                            <FormFeedback>Oh noes! pick a correct authorised signatory name for shop</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="category_id">Select(Multiple) Dealing Item Groups</Label>
                                                            <Input type="select" name="group_id" id="group_id" bsSize="sm"
                                                                   invalid={HelperFunctions.isNotEmpty(errors["group_id"])}
                                                                   onChange={this.onGroupChange.bind(this, "group_id")} value={data["group_id"]} multiple>
                                                                {groups && groups.length > 0 && groups.map((item, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Oh noes! pick a correct category for shop</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="user_id">Registered User(Shop Owner) Mobile/ID</Label>
                                                            <Input disabled={APIEndpoints.appUser_User_ID} type="text" name="user_id" id="user_id" bsSize="sm" placeholder="" value={data["user_id"]}
                                                                   invalid={HelperFunctions.isNotEmpty(errors["user_id"])} onChange={this.onInputChange.bind(this, "user_id")}/>
                                                            <FormFeedback>Oh noes! Enter valid user mobile/id</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="establishment_year">Establishment Year</Label>
                                                            <Input type="select" name="establishment_year" id="establishment_year" bsSize="sm"
                                                                   invalid={HelperFunctions.isNotEmpty(errors["establishment_year"])}
                                                                   onChange={this.onInputChange.bind(this, "establishment_year")} value={data["establishment_year"]}>
                                                                {estaYears && estaYears.length > 0 && estaYears.map((item, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={item}>{item}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Oh noes! pick a correct establishment year</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="gst_number">GST Number</Label>
                                                            <Input type="text" name="gst_number" id="gst_number" bsSize="sm" placeholder="" invalid={HelperFunctions.isNotEmpty(errors["gst_number"])}
                                                                   value={data["gst_number"]} onChange={this.onInputChange.bind(this, "gst_number")}/>
                                                            <FormFeedback>Oh noes! pick a correct gst number</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="working_days_hours">Working Days and Timings</Label>
                                                            <Input type="text" name="working_days_hours" id="working_days_hours" bsSize="sm" invalid={HelperFunctions.isNotEmpty(errors["working_days_hours"])}
                                                                   placeholder="Monday-Saturday 9AM-6PM" value={data["working_days_hours"]}
                                                                   onChange={this.onInputChange.bind(this, "working_days_hours")}/>
                                                            <FormFeedback>Oh noes! pick a correct working days & timings</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="fb_link">Facebook Link</Label>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <FontAwesomeIcon
                                                                            icon={faFacebookSquare}/></InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type="text" name="fb_link" id="fb_link" bsSize="sm" placeholder="" value={data["fb_link"]}
                                                                       invalid={HelperFunctions.isNotEmpty(errors["fb_link"])}
                                                                       onChange={this.onInputChange.bind(this, "fb_link")}/>
                                                                <FormFeedback>Oh noes! pick a correct fb link</FormFeedback>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="insta_link">Instagram Link</Label>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <FontAwesomeIcon
                                                                            icon={faInstagram}/></InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type="text" name="insta_link" id="insta_link" bsSize="sm" placeholder="" value={data["insta_link"]}
                                                                       invalid={HelperFunctions.isNotEmpty(errors["insta_link"])}
                                                                       onChange={this.onInputChange.bind(this, "insta_link")}/>
                                                                <FormFeedback>Oh noes! pick a correct instagram link</FormFeedback>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="address">Address</Label>
                                                            <Input invalid={HelperFunctions.isNotEmpty(errors["address"])} bsSize="sm" type="text" name="address" id="address"
                                                                   value={data["address"]} onChange={this.onInputChange.bind(this, "address")}/>
                                                            <FormFeedback>{errors["address"]}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="landmark">Map Location(Landmark)</Label>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <FontAwesomeIcon
                                                                            icon={faMapMarked}/></InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input type="text" name="landmark" id="landmark" bsSize="sm" placeholder="" value={data["landmark"]}
                                                                       invalid={HelperFunctions.isNotEmpty(errors["landmark"])}
                                                                       onChange={this.onInputChange.bind(this, "landmark")}/>
                                                                <FormFeedback>Oh noes! pick a correct map location</FormFeedback>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label for="pin_code">Pin Code</Label>
                                                            <Input invalid={HelperFunctions.isNotEmpty(errors["pin_code"])} bsSize="sm" type="text" name="pin_code" id="pin_code"
                                                                   value={data["pin_code"]} onChange={this.onInputChange.bind(this, "pin_code")}/>
                                                            <FormFeedback>{errors["pin_code"]}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="country_id">Select Country</Label>
                                                            <Input type="select" name="country_id" id="country_id" bsSize="sm" value={data["country_id"]} invalid={HelperFunctions.isNotEmpty(errors["country_id"])}
                                                                   onChange={this.countryChange}>
                                                                {countries && countries.length > 0 && countries.map((item, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>{errors["country_id"]}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="state_id">Select State</Label>
                                                            <Input type="select" name="state_id" id="state_id" bsSize="sm" value={data["state_id"]} invalid={HelperFunctions.isNotEmpty(errors["state_id"])}
                                                                   onChange={this.stateChange}>
                                                                {states && states.length > 0 && states.map((item, index) => {
                                                                    return (
                                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>{errors["state_id"]}</FormFeedback>
                                                            {isRequestingForWhom === 'state/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                                <div className="spinner-border spinner-border-sm text-primary mt-4" role="status"/>
                                                            </div>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="city_id">Select City</Label>
                                                            <Input type="select" name="city_id" id="city_id" bsSize="sm" value={data["city_id"]} invalid={HelperFunctions.isNotEmpty(errors["city_id"])}
                                                                   onChange={this.cityChange}>
                                                                {cities && cities.length > 0 && cities.map((item, index) => {
                                                                    return (
                                                                        <option id={"" + item.name} key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            <FormFeedback>{errors["city_id"]}</FormFeedback>
                                                            {isRequestingForWhom === 'city/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                                <div className="spinner-border spinner-border-sm text-primary mt-4" role="status"/>
                                                            </div>}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="12">
                                                        <FormGroup className={"mb-0"}>
                                                            <Label for="description">Shop Specifics</Label>
                                                            {/*<Input type="textarea" name="description" id="description" bsSize="sm" placeholder="" value={data["description"]}
                                                                   onChange={this.onInputChange.bind(this, "description")} style={{resize: 'none'}}/>*/}
                                                            {/*<Editor
                                                                value={data["description"]}
                                                                name="description" id="description" bsSize="sm" placeholder=""
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false
                                                                }}
                                                                style={{resize: 'none'}}
                                                                onEditorChange={this.handleEditorChange}
                                                            />*/}
                                                            <SunEditor name="description" id="description" bsSize="sm"
                                                                       placeholder="Any Speciality, shop keeper want to display on his page. Please enter here." height={300} style={{resize: 'none'}}
                                                                       defaultValue={data["description"]}
                                                                       onChange={this.handleEditorChange}/>
                                                            <FormFeedback>Oh noes! pick a correct description</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className={"justify-content-center mt-2"}>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting &&
                                            <span className="spinner-grow spinner-grow-sm mr-1"
                                                  role="status"
                                                  aria-hidden="true"/>}
                                            {isRequesting ? this.isUpdate ? "Updating...." : "Adding..." : this.isUpdate ? "Update" : "Add"}
                                        </Button>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}