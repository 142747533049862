import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import DesignationAdd from "./add";
import DesignationList from "./list";
import DesignationUpdate from "./update";

export default class Designation extends React.Component {

    list = (props) => (
        this.props.userData.permissions.employee_designation_list ?
            <DesignationList {...props} userData={this.props.userData} getDesignation={this.props.getDesignation} designations={this.props.designations} updateDesignationStatus={this.props.updateDesignationStatus}
                             isRequestingForWhom={this.props.isRequestingForWhom}/>
            : <ContentMessagePopup/>
    );
    add = (props) => (
        this.props.userData.permissions.employee_designation_add ?
            <DesignationAdd {...props} addDesignation={this.props.addDesignation} isRequesting={this.props.isRequestingForWhom === 'designation/add'}/>
            : <ContentMessagePopup/>
    );
    update = (props) => (
        this.props.userData.permissions.employee_designation_edit ?
            <DesignationUpdate {...props} addDesignation={this.props.addDesignation} isRequesting={this.props.isRequestingForWhom === 'designation/add'}/>
            : <ContentMessagePopup/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.designation.url} render={() => (
                    <Redirect to={ProjectRoutes.designation.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.designation.list.url} render={this.list}/>
                <Route path={ProjectRoutes.designation.add.url} render={this.add}/>
                <Route path={ProjectRoutes.designation.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
