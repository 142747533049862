import React, {Component} from 'react';
import PropTypes from "prop-types";

class ContentMessagePopup extends Component {
    render() {
        return (
            <div className={`message-container ${this.props.animationClass}`}>
                <h4>{this.props.message}</h4>
            </div>
        );
    }
}

ContentMessagePopup.propTypes = {
    animationClass: PropTypes.string,
    message: PropTypes.string,
};

ContentMessagePopup.defaultProps = {
    animationClass: "",
    message: "Permissions Required! Please Contact Admin.",
}

export default ContentMessagePopup;