import {faAngleDoubleDown, faAngleDoubleUp, faLevelDownAlt, faLevelUpAlt, faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table, UncontrolledTooltip} from 'reactstrap';
import images from "../../../../../../assets/images";
import APIEndpoints from "../../../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import Constants from "../../../../../../utils/Constants";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";

export default class ProductList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                price: '',
                price_type: '=',
                discount: '',
                discount_type: '=',
                stock: '',
                stock_type: '=',
                shop_id: HelperFunctions.getQueryStringValue("shop_id")
            },
            selectedGroupNumber: 0,
            selectedCategroyNumber: 0,
            currentPage: 1
        }
    }

    componentDidMount() {
        this.props.getProduct('', '', true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
        window.userCollapse();
    }

    groupChange = (event) => {
        this.props.getProduct(this.props.groups[event.target.value].id, '', true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1});
        this.props.getCategory(this.props.groups[event.target.value].id, false);
        this.setState({selectedGroupNumber: event.target.value, currentPage: 1})
    };

    categoryChange = (event) => {
        this.props.getProduct(this.props.groups[this.state.selectedGroupNumber].id, this.props.categories[event.target.value].id, true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1});
        this.setState({selectedCategroyNumber: event.target.value, currentPage: 1})
    };

    shopChange = (event) => {
        const shop_id = event.target.value;
        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
        const c = this.props.categories ? this.props.categories[this.state.selectedCategroyNumber].id : '';
        this.props.getProduct(g, c, true, {
            ...this.state.data,
            page_size: Constants.defaultPageSize,
            page_number: 1,
            shop_id
        });
        this.onInputChange("shop_id", event)
    };

    updateStatus = (product_id, status) => {
        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
        const c = this.props.categories ? this.props.categories[this.state.selectedCategroyNumber].id : '';
        this.props.updateProductStatus(g, c, product_id, status, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage})
    };
    updateTrending = (product_id, status) => {
        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
        const c = this.props.categories ? this.props.categories[this.state.selectedCategroyNumber].id : '';
        const p = this.state.data.shop_id;
        this.props.updateProductTrendingStatus(g, c, p, product_id, status, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage})
    };
    updateNew = (product_id, status) => {
        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
        const c = this.props.categories ? this.props.categories[this.state.selectedCategroyNumber].id : '';
        const p = this.state.data.shop_id;
        this.props.updateProductNewStatus(g, c, p, product_id, status, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage})
    };

    paginationClick = (pageNum) => {

        const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
        const c = this.props.categories ? this.props.categories[this.state.selectedCategroyNumber].id : '';

        this.props.getProduct(g, c, true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});

        this.setState({currentPage: pageNum});
    };

    renderPagination = () => {
        const {totalProductCount} = this.props;
        const {currentPage} = this.state;
        if (totalProductCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalProductCount / Constants.defaultPageSize);

            const pages = [];
            for (let i = 0; i < numPages; i++) {
                pages.push(<li key={"pg-" + i} className={`page-item mx-1 ${currentPage === (i + 1) ? "active" : ""}`} onClick={this.paginationClick.bind(this, (i + 1))}><a className="page-link py-1">{i + 1}</a></li>);
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="pt-5" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage - 1))} aria-label="Previous"><span
                            aria-hidden="true">«</span></a></li>
                        {pages}
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage + 1))} aria-label="Next"><span
                            aria-hidden="true">»</span></a></li>
                    </ul>
                </nav>
            );
        }
        else return null;
    };

    reset = () => {
        this.setState({
            data: {
                name: '',
                price: '',
                price_type: '=',
                discount: '',
                discount_type: '=',
                stock: '',
                stock_type: '='
            }
        });
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "product/list")) {
            const g = this.props.groups ? this.props.groups[this.state.selectedGroupNumber].id : '';
            const c = this.props.categories ? this.props.categories[this.state.selectedCategroyNumber].id : '';
            this.props.getProduct(g, c, true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1}, "product/search");

            this.setState({currentPage: 1});
        }
    };

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    renderFilter = () => {
        const {isRequestingForWhom} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible py-2 text-muted">Looking for something? <span className="text-success">Click here</span> to refine your search.</button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form className="pt-2">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input type="text" name="name" id="name" bsSize="sm" value={data["name"]}
                                           onChange={this.onInputChange.bind(this, "name")}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="price">Price</Label>
                                    <Row form>
                                        <Col md={4}>
                                            <Input type="select" name="price_type" id="price_type" bsSize="sm" value={data["price_type"]} onChange={this.onInputChange.bind(this, "price_type")}>
                                                <option value="=">{'= equal to'}</option>
                                                <option value=">">{'> More Than'}</option>
                                                <option value="<">{'< Less Than'}</option>
                                            </Input>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="text" name="price" id="price" bsSize="sm" value={data["price"]}
                                                   onChange={this.onInputChange.bind(this, "price")}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="stock">Stock</Label>
                                    <Row form>
                                        <Col md={4}>
                                            <Input type="select" name="stock_type" id="stock_type" bsSize="sm" value={data["stock_type"]} onChange={this.onInputChange.bind(this, "stock_type")}>
                                                <option value="=">{'= equal to'}</option>
                                                <option value=">">{'> More Than'}</option>
                                                <option value="<">{'< Less Than'}</option>
                                            </Input>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="text" name="stock" id="stock" bsSize="sm" value={data["stock"]}
                                                   onChange={this.onInputChange.bind(this, "stock")}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="discount">Discount</Label>
                                    <Row form>
                                        <Col md={4}>
                                            <Input type="select" name="discount_type" id="discount_type" bsSize="sm" value={data["discount_type"]} onChange={this.onInputChange.bind(this, "discount_type")}>
                                                <option value="=">{'= equal to'}</option>
                                                <option value=">">{'> More Than'}</option>
                                                <option value="<">{'< Less Than'}</option>
                                            </Input>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="text" name="discount" id="discount" bsSize="sm" value={data["discount"]}
                                                   onChange={this.onInputChange.bind(this, "discount")}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "product/search") && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "product/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const {groups, categories, products, isRequestingForWhom, updateProductStatus, shops} = this.props;
        const productsLength = products ? products.length : null;

        console.log(shops);
        const {data} = this.state;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Product"
                    subheading="Available Product for Shri Agrawal Samaj Pranyas."
                    icon="pe-7s-cart icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        this.props.userData.permissions.product_add && <Link to={ProjectRoutes.sale.product.add.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">Add Product</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        Product List
                                        <div className="btn-actions-pane-right">
                                            <Row form>
                                                <Col style={{minWidth: 180}}>
                                                    <Input type="select" name="groups" id="groups" bsSize="sm" className="mt-1"
                                                           onChange={this.groupChange}>
                                                        {groups && groups.length > 0 && groups.map((product, index) => {
                                                            return (
                                                                <option key={"" + index}
                                                                        value={index}>{product.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </Col>
                                                <Col style={{minWidth: 180}}>
                                                    <Input type="select" name="categories" id="categories" bsSize="sm" className="mt-1"
                                                           onChange={this.categoryChange}>
                                                        {categories && categories.length > 0 && categories.map((product, index) => {
                                                            return (
                                                                <option key={"" + index}
                                                                        value={index}>{product.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </Col>
                                                <Col style={{minWidth: 180}}>
                                                    <Input disabled={APIEndpoints.appUser_User_ID} type="select" name="shops" id="shops" className="mt-1"
                                                           bsSize="sm" value={data.shop_id}
                                                           onChange={this.shopChange}>
                                                        {shops && shops.length > 0 && shops.map((product, index) => {
                                                            return (
                                                                <option key={"" + index}
                                                                        value={product.id}>{product.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {this.renderFilter()}
                                    {productsLength > 0 && (
                                        <React.Fragment>
                                            <Table responsive striped className="mb-0">
                                                <thead>
                                                <tr>
                                                    <th>Images</th>
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>Stock</th>
                                                    <th>Discount</th>
                                                    {/*<th>Tag</th>*/}
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {products.map((product, index) => {
                                                    const obj = {};
                                                    if (HelperFunctions.isActive(product.status)) {
                                                        obj.icon = faThumbsDown;
                                                        obj.ttxt = "Make Inactive";
                                                        obj.color = "danger";
                                                        obj.statusToChange = "Inactive";
                                                    } else {
                                                        obj.icon = faThumbsUp;
                                                        obj.ttxt = "Make Active";
                                                        obj.color = "success";
                                                        obj.statusToChange = "Active";
                                                    }

                                                    const objTrend = {};
                                                    if (product.is_trending) {
                                                        objTrend.icon = faLevelDownAlt;
                                                        objTrend.ttxt = "Remove Trending";
                                                        objTrend.color = "primary";
                                                        objTrend.statusToChange = false;
                                                    } else {
                                                        objTrend.icon = faLevelUpAlt;
                                                        objTrend.ttxt = "Make Trending";
                                                        objTrend.color = "primary";
                                                        objTrend.statusToChange = true;
                                                    }

                                                    const objNew = {};
                                                    if (product.is_new) {
                                                        objNew.icon = faAngleDoubleDown;
                                                        objNew.ttxt = "Remove New";
                                                        objNew.color = "secondary";
                                                        objNew.statusToChange = false;
                                                    } else {
                                                        objNew.icon = faAngleDoubleUp;
                                                        objNew.ttxt = "Set New";
                                                        objNew.color = "secondary";
                                                        objNew.statusToChange = true;
                                                    }

                                                    return (
                                                        <tr key={"" + index}>
                                                            <td>
                                                                <img className={"product-list-img"} src={product.image_url1 ? product.base_url + product.image_url1 : images.cameraPhoto} alt=""/>
                                                            </td>
                                                            <td>{product.name}</td>
                                                            <td>{'₹' + product.rent_per_product}</td>
                                                            <td>{product.total_quantity + ' Left'}</td>
                                                            <td>{product.discount + '%'}</td>
                                                            {/*<td>{product.badge_text && <span className={`badge rounded-0 text-white ${product.badge_class}`}>{product.badge_text}</span>}</td>*/}
                                                            <td>
                                                                <div className="row">

                                                                    {this.props.userData.permissions.product_edit && (
                                                                        <Link to={{
                                                                            pathname: ProjectRoutes.sale.product.update.url,
                                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                                            state: {
                                                                                product: product
                                                                            }
                                                                        }}>
                                                                            <Button className="btn-shadow mr-2 btn-sm mt-1" color="info">
                                                                                <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                            </Button>
                                                                        </Link>
                                                                    )}

                                                                    {this.props.userData.permissions.product_active_inactive && (
                                                                        <Button id={"btn" + index}
                                                                                className="btn-shadow mr-2 btn-sm mt-1" color={obj.color}
                                                                                onClick={this.updateStatus.bind(this, product.id, obj.statusToChange)}
                                                                        >
                                                                            <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                            <UncontrolledTooltip placement="left"
                                                                                                 target={"btn" + index}>
                                                                                {obj.ttxt}
                                                                            </UncontrolledTooltip>
                                                                        </Button>
                                                                    )}
                                                                    {/*<Button id={"btn-trend" + index}
                                                                            className="btn-shadow mr-2 btn-sm mt-1" color={objTrend.color}
                                                                            onClick={this.updateTrending.bind(this, product.id, objTrend.statusToChange)}
                                                                    >
                                                                        <FontAwesomeIcon icon={objTrend.icon} size={"1x"}/>
                                                                        <UncontrolledTooltip placement="left"
                                                                                             target={"btn-trend" + index}>
                                                                            {objTrend.ttxt}
                                                                        </UncontrolledTooltip>
                                                                    </Button>
                                                                    <Button id={"btn-new" + index}
                                                                            className="btn-shadow mr-2 btn-sm mt-1" color={objNew.color}
                                                                            onClick={this.updateNew.bind(this, product.id, objNew.statusToChange)}
                                                                    >
                                                                        <FontAwesomeIcon icon={objNew.icon} size={"1x"}/>
                                                                        <UncontrolledTooltip placement="left"
                                                                                             target={"btn-new" + index}>
                                                                            {objNew.ttxt}
                                                                        </UncontrolledTooltip>
                                                                    </Button>*/}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    )}
                                    {productsLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No Product
                                            found for selected category!</div>
                                    )}
                                    {(productsLength === null || isRequestingForWhom === 'product/list') && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "product/update-status" &&
                                <div className="d-flex justify-content-center align-products-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
