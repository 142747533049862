import React from 'react';
import {Link} from "react-router-dom";
import HelperFunctions from "../../../../utils/HelperFunctions";
import $ from 'jquery';

export default class LinkComponent extends React.PureComponent {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
    }


    onClick(e) {
        if (this.props.hasSubMenu) this.props.toggleSubMenu(e);
        else {
            this.props.activateMe({
                newLocation: this.props.to,
                selectedMenuLabel: this.props.label,
            });
            $('.sidebar-mobile-overlay').trigger("click");
        }
    }

    render() {
        const {to, active} = this.props;
        return (
            <Link to={to + HelperFunctions.getQueryStringFromURL()} className={`metismenu-link ${active ? "active" : ''}`} onClick={this.onClick}>
                {this.props.children}
            </Link>
        );
    }
};