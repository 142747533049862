import React, {PureComponent} from 'react';
import {withRouter} from "react-router-dom";
import APIEndpoints from "../../../config/APIEndpoints";
import ProjectRoutes from "../../../config/routes/projectRoutes";
import HelperFunctions from "../../../utils/HelperFunctions";

class ScrollToTop extends PureComponent {

    componentDidMount() {
        window.addEventListener("message", this.onMessage);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onMessage);
    }

    onMessage = (e) => {
        if (e.data && e.data.from && (e.data.from === 'react_js')) {
            if (e.data.hasOwnProperty('user_unauthenticated') && APIEndpoints.appUser_User_ID === undefined) {
                this.props.history.replace({
                    pathname: ProjectRoutes.user.login.url,
                    search: HelperFunctions.getQueryStringFromURL(),
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.state && this.props.location.state.is_scroll_top === false) {
            return;
        }
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);