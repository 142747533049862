import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";

export default class CategoryUpdate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.location.state ? this.props.location.state.name : '',
            group_id: this.props.location.state ? this.props.location.state.group_id : '',
        }
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    };

    add = () => {
        if (!this.props.isRequesting) {
            this.props.addCategory(this.state.name, this.state.group_id, this.props.location.state.id);
        }
    };

    change = (event) => {
        this.setState({
            group_id: event.target.value
        });
    };

    render() {
        const {name, group_id} = this.state;
        const {isRequesting, groups} = this.props;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Categories"
                    subheading="Update a category for Shri Agrawal Samaj Pranyas business."
                    icon="pe-7s-server icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.category.list.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">List Category</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="6" md={{size: 6, offset: 3}}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Update Category</CardTitle>
                                    <Form>
                                        <FormGroup>
                                            <Label for="coutry">Select Group</Label>
                                            <Input type="select" name="select" id="group" onChange={this.change} value={group_id}>
                                                {groups && groups.length > 0 && groups.map((item, index) => {
                                                    return (
                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Category</Label>
                                            <Input type="text" name="name" id="name" placeholder="pick a category..." value={name} onChange={this.onNameChange}/>
                                            <FormFeedback>Oh noes! pick a correct name for category</FormFeedback>
                                        </FormGroup>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                            {isRequesting ? "Updating..." : "Update"}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
