import Reactotron from 'reactotron-react-js'
import images from "../assets/images";
import APIEndpoints from "../config/APIEndpoints";
import Constants from "./Constants";

function toFixedToOnePlace(number) {
    if (number && (number % 1 > 0)) {
        return number.toFixed(2);
    }
    return '' + number;
}

// get the top route name in any navigator
function getRouteNameInNavigator(navigator, index) {
    return navigator.routes[index !== undefined ? index : navigator.index].routeName;
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function log(value, important) {
    Reactotron.log(value, important);
}

function copyObject(object) {
    return JSON.parse(JSON.stringify(object));
}

function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

function appendZero(number) {
    return number < 10 ? '0' + number : '' + number
}

function postMessageToGame(message) {
    message.from = 'react_js';
    window.postMessage(message, "*");
    if (message.current_game_url) {
        switch (message.current_game_url) {
            case "/training/juggling":
                changeFavicon(images.jugglingFavicon);
                break;
            case "/training/dribbling":
                changeFavicon(images.dribblingFavicon);
                break
            default:
                changeFavicon(images.szFavicon);
        }
    }
}

function postMessageToReact(message) {
    message.from = 'play_canvas';
    window.postMessage(message, "*");
}

function isAlphaNumeric(value) {
    var letterNumber = /^[a-z0-9]+$/i;
    if (value.match(letterNumber))
        return true;
    else
        return false;
}

function isAlphaNumericUnderscore(value) {
    var letterNumber = /^[a-z0-9_]+$/i;
    if (value.match(letterNumber))
        return true;
    else
        return false;
}

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/;";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function getCurrency(currencyValue) {
    switch (currencyValue) {
        case 'GBP':
            return "£";
            break;
        case 'USD':
            return "$";
            break;
        case 'EUR':
            return "€";
            break;
        default:
            return "£";
            break;
    }
}

function getCurrencyName(currencyValue) {
    switch (currencyValue) {
        case 'GBP':
            return "pounds";
            break;
        case 'USD':
            return "dollars";
            break;
        case 'EUR':
            return "euros";
            break;
        default:
            return "euros";
            break;
    }
}

function getCurrencyImage(currencyValue) {
    switch (currencyValue) {
        case 'GBP':
            return "assets/images/pound.png";
            break;
        case 'USD':
            return "assets/images/dollar.png";
            break;
        case 'EUR':
            return "assets/images/euro.png";
            break;
        default:
            return "assets/images/euro.png";
            break;
    }
}

function redirectToLoginSSO() {
    window.location.href = APIEndpoints.identitySSO.url;
}

function redirectToRegisterSSO() {
    window.location.href = APIEndpoints.registerIdentitySSO.url;
}

function redirectoToPP() {
    window.location.href = APIEndpoints.paddypowerURL.url;
}

function numberFormatter(number) {
    return number.toLocaleString('en');
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function generateRangeForRange(input, inputLow, inputHigh, outputLow, outputHigh) {
    return ((input - inputLow) / (inputHigh - inputLow)) * (outputHigh - outputLow) + outputLow;
}

function getQueryStringFromURL() {
    return window.location.search;
}

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calculateLevelBarWidth(userXPData) {
    let xpRequired = userXPData.upcoming_level.xp_points - userXPData.user_level.xp_points;
    let totalXP = userXPData.user_xp_points - userXPData.user_level.xp_points;
    const per = (totalXP / xpRequired) * 100;
    return per === 0 ? 0 : generateRangeForRange(per, 0, 100, 4, 100);
}

function getRequiredXPForNextLevel(nextLevelXP, currentXP) {
    return (nextLevelXP - currentXP)
}

function toURLEncoded(data) {
    const dataToPass = Object.keys(data).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataToPass;
}

function validateSpecialChar(text) {
    //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
    var regex = /^[A-Za-z0-9]*$/;
    //Validate TextBox value against the Regex.
    var isValid = regex.test(text);
    return isValid;
}

function lockedTab(data, tab) {
    let flag = null;
    if (data !== null && data.user_level.level_id) {
        let currentLevel = data.user_level.level_id;
        let definedLevel = tab === "store" ? Constants.itemsTabLevel : Constants.trainingTabLevel;
        if (currentLevel) {
            if (currentLevel < definedLevel) {
                flag = true;
            } else {
                flag = false;
            }
        }
    }
    return flag
}

function changeFavicon(src) {
    src = src + '?=' + Math.random(); // no cache for favicon
    var link = document.createElement('link'),
        oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.href = src;
    if (oldLink) {
        document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
}

function diffArray(arr1, arr2) {
    var newArr = [];

    arr1.map(function (val) {
        if (arr2.indexOf(val) < 0) {
            newArr.push(val)
        }
    });

    arr2.map(function (val) {
        if (arr1.indexOf(val) < 0) {
            newArr.push(val)
        }
    });

    return newArr;
}

function blockEvent(event) {
    event.stopPropagation();
    event.preventDefault();
}

function returnTime(object) {
    var remainingMilliSeconds = object.milliSeconds;
    var timeObbj = convertMS(remainingMilliSeconds);
    var remainingHours = timeObbj.hour;
    var remainingDay = timeObbj.day;
    var remainingMinutes = timeObbj.minute;
    var remainingSeconds = timeObbj.second;
    var dayToShow = parseInt(remainingDay) > 0 ? (remainingDay) : '00';
    var hourToShow = parseInt(remainingHours) > 0 ? (remainingHours) : '00';
    var minuteToShow = parseInt(remainingMinutes) ? (remainingMinutes) : '00';
    var secondToShow = remainingSeconds;

    var timerValues = {
        isComplete: object.isComplete,
        dayToShow: dayToShow,
        hourToShow: hourToShow,
        minuteToShow: minuteToShow,
        secondToShow: secondToShow,
    };
    return timerValues;
}

function convertMS(milliseconds) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: appendZero(day),
        hour: appendZero(hour),
        minute: appendZero(minute),
        second: appendZero(seconds)
    };
}

function detectDevice() {
    return window.gameOrientation.device;
}

function getGameScenesIds() {
    return {
        "Main": "639451",
        "Dashboard": "762288",
        "Juggling": "762075",
        "Dribbling": "762076"
    };
}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function isActive(status) {
    return status === "Active";
}

function getActive(data, arr = null) {
    if (data && data.length > 0) {
        arr = arr ? arr : [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].status === "Active")
                arr.push(data[i]);
        }
        return arr;
    } else {
        return data;
    }
}

function getActiveForSelect(data, title) {
    if (data && data.length > 0) {
        const arr = [title];
        return getActive(data, arr);
    } else {
        return data;
    }
}

function isNotEmpty(str) {
    return (str !== undefined && str !== null && str.length > 0)
}

function convertNumberToKs(number) {
    if (number > 1000) {
        return toFixedToOnePlace(number / 1000) + 'K';
    }
    return number;
}

function getYears() {
    const year = new Date().getFullYear();
    const yearArr = ["--Select Year--"];
    for (let i = year; i >= 1950; i--) {
        yearArr.push("" + i);
    }
    return yearArr;
}

const HelperFunctions = {
    getRouteNameInNavigator,
    hexToRgb, isNotEmpty,
    log,
    getCurrencyImage,
    numberFormatter,
    copyObject,
    toFixedToOnePlace,
    getQueryStringValue,
    appendZero,
    postMessageToGame,
    postMessageToReact,
    isAlphaNumeric,
    isAlphaNumericUnderscore,
    createCookie,
    readCookie,
    eraseCookie,
    getCurrency,
    getCurrencyName,
    redirectToLoginSSO,
    redirectoToPP,
    redirectToRegisterSSO,
    getUrlVars,
    generateRangeForRange,
    getQueryStringFromURL,
    getRandomArbitrary,
    ordinal_suffix_of,
    numberWithCommas,
    calculateLevelBarWidth,
    getRequiredXPForNextLevel,
    toURLEncoded,
    validateSpecialChar,
    lockedTab,
    changeFavicon,
    diffArray,
    blockEvent,
    returnTime,
    convertMS,
    detectDevice,
    getGameScenesIds,
    formatNumber,
    isActive,
    getActiveForSelect,
    getActive, convertNumberToKs, getYears
};

export default HelperFunctions;