/*
* This is Navigation Container
* Presentational component for this container is RootNavigator (/component/Navigator/RootNavigator)
* Container is connected with store with the help of connect() function
* in connect() function mapStateToProps and container is passed
* */

import cx from 'classnames';
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ResizeDetector from 'react-resize-detector';
import ContentLoader from "../../../../commons/components/contentLoader";
import APIEndpoints from "../../../../config/APIEndpoints";
import common from "../../common";
import user from "../../screens/user";
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import RootNavigator from "./components";
import * as constants from './constants';
import reducer from "./reducer";

class RootNavigatorSmart extends Component {
    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.timeout = null;
        this.resize();

        // get the common APIs only for once from common actions
        this.props.callOnPageLoad();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        let self = this;
        if (self.timeout) {
            clearTimeout(self.timeout);
        }
        this.timeout = setTimeout(() => {
            self.props.setCurrentOrientation();
            clearTimeout(self.timeout);
        }, 100);
    };

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt, userData
        } = this.props;

        // until user check API is finished show Content loader lese start with pages
        if (userData === undefined) return <ContentLoader/>;

        return (
            <ResizeDetector
                handleWidth
                render={({width}) => (
                    <Fragment>
                        {APIEndpoints.appUser_User_ID ? (
                            <RootNavigator {...this.props}/>
                        ) : (
                            <div className={cx(
                                "app-container app-theme-" + colorScheme,
                                {'fixed-header': enableFixedHeader},
                                {'fixed-sidebar': enableFixedSidebar || width < 1250},
                                {'fixed-footer': enableFixedFooter},
                                {'closed-sidebar': enableClosedSidebar || width < 1250},
                                {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                                {'sidebar-mobile-open': enableMobileMenu},
                            )}>
                                <RootNavigator {...this.props}/>
                            </div>
                        )}
                    </Fragment>
                )}
            />
        );
    }
}

const mapStateToProps = state => ({
    colorScheme: state[common.constants.NAME].colorScheme,
    enableFixedHeader: state[common.constants.NAME].enableFixedHeader,
    enableMobileMenu: state[common.constants.NAME].enableMobileMenu,
    enableFixedFooter: state[common.constants.NAME].enableFixedFooter,
    enableFixedSidebar: state[common.constants.NAME].enableFixedSidebar,
    enableClosedSidebar: state[common.constants.NAME].enableClosedSidebar,
    enablePageTabsAlt: state[common.constants.NAME].enablePageTabsAlt,
    userData: state[user.constants.NAME].userData,
});

const mapDispatchToProps = dispatch => {
    return {
        setCurrentOrientation: (currentOrientation) => dispatch(common.actions.setCurrentOrientation(currentOrientation)),
        callOnPageLoad: (currentOrientation) => dispatch(common.actions.callOnPageLoad()),
    }
};

export const RootNavigatorContainer = connect(mapStateToProps, mapDispatchToProps)(RootNavigatorSmart);
export default {actionTypes, actions, reducer, constants};