import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import CategoryAdd from "./add";
import CategoryList from "./list";
import CategoryUpdate from "./update";

export default class Category extends React.Component {

    list = (props) => (
        this.props.userData.permissions.shop_categories_list ?
            <CategoryList {...props} userData={this.props.userData} getCategory={this.props.getCategory} groups={this.props.groups} categories={this.props.categories}
                          updateCategoryStatus={this.props.updateCategoryStatus}
                          isRequestingForWhom={this.props.isRequestingForWhom}/>
            : <ContentMessagePopup/>
    );
    add = (props) => (
        this.props.userData.permissions.shop_categories_add ?
            <CategoryAdd {...props} groups={this.props.groups} addCategory={this.props.addCategory} isRequesting={this.props.isRequestingForWhom === 'sale/category/add'}/>
            : <ContentMessagePopup/>
    );
    update = (props) => (
        this.props.userData.permissions.shop_categories_edit ?
            <CategoryUpdate {...props} groups={this.props.groups} addCategory={this.props.addCategory} isRequesting={this.props.isRequestingForWhom === 'sale/category/add'}/>
            : <ContentMessagePopup/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.category.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.category.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.category.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.category.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.category.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
