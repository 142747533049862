import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import ProductAdd from "./add";
import ProductList from "./list";

export default class Product extends React.Component {

    list = (props) => (
        this.props.userData.permissions.product_list ?
            <ProductList {...props} userData={this.props.userData} getProduct={this.props.getProduct}
                         getCategory={this.props.getCategory}
                         groups={this.props.groups} categories={this.props.categories}
                         shops={this.props.shops} products={this.props.products} totalProductCount={this.props.totalProductCount}
                         updateProductStatus={this.props.updateProductStatus}
                         updateProductTrendingStatus={this.props.updateProductTrendingStatus}
                         updateProductNewStatus={this.props.updateProductNewStatus}
                         isRequestingForWhom={this.props.isRequestingForWhom}/>
            : <ContentMessagePopup/>
    );

    add = (props) => (
        this.props.userData.permissions.product_add ?
            <ProductAdd {...props} getCategory={this.props.getCategory} addProduct={this.props.addProduct} groups={this.props.groups}
                        categories={this.props.categories}
                        productAddData={this.props.productAddData}
                        shops={this.props.shops}
                        isRequesting={this.props.isRequestingForWhom === 'product/add'}/>
            : <ContentMessagePopup/>
    );
    update = (props) => (
        this.props.userData.permissions.product_edit ?
            <ProductAdd {...props} getCategory={this.props.getCategory} addProduct={this.props.addProduct} groups={this.props.groups}
                        categories={this.props.categories} shops={this.props.shops}
                        productAddData={this.props.productAddData}
                        isRequesting={this.props.isRequestingForWhom === 'product/add'}/>
            : <ContentMessagePopup/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.product.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.product.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.product.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.product.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.product.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
