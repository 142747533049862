import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faMapMarked} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from 'reactstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import images from "../../../../../assets/images";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";
import ImageCrop from "./ImageCrop";
import "./index.css";

export default class ServicesAdd extends React.Component {

    constructor(props) {
        super(props);

        this.isUpdate = (this.props.location.state && this.props.location.state.services) ? true : false;
        const obj = this.isUpdate ? this.props.location.state.services : {};
        if (this.isUpdate) {
            obj.group_id = obj.group_ids ? obj.group_ids : [];
        }

        this.state = {
            data: {
                name: '',
                description: '',
                ...obj,
                image_url1: this.isUpdate ? obj.image_url1 ? obj.image_url1 : '' : '',
                image_url2: this.isUpdate ? obj.image_url2 ? obj.image_url2 : '' : '',
                image_url3: this.isUpdate ? obj.image_url3 ? obj.image_url3 : '' : '',
                image_url4: this.isUpdate ? obj.image_url4 ? obj.image_url4 : '' : '',
                image_url5: this.isUpdate ? obj.image_url5 ? obj.image_url5 : '' : '',
                image_url6: this.isUpdate ? obj.image_url6 ? obj.image_url6 : '' : '',
            },
            file1: '',
            file2: '',
            file3: '',
            file4: '',
            file5: '',
            file6: '',
            selectedImageNumber: null,
        };

    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    handleEditorChange = (content, editor) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy['description'] = content;
        this.setState({
            data: dataCopy
        });
    };

    add = () => {
        if (!this.props.isRequesting) {
            const data = new FormData();
            for (const key in this.state.data) {
                data.append(key, this.state.data[key]);
            }

            data.append("file1", this.state.file1);
            data.append("file2", this.state.file2);
            data.append("file3", this.state.file3);
            data.append("file4", this.state.file4);
            data.append("file5", this.state.file5);
            data.append("file6", this.state.file6);

            this.props.addServices(data);
        }
    };

    selectFile = (selectedFileInput) => {
        this.setState({selectedImageNumber: selectedFileInput})
    };

    hideImageCrop = () => {
        this.setState({selectedImageNumber: null});
    };

    getCroppedImage = (croppedFileObj) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy["image_url" + this.state.selectedImageNumber] = croppedFileObj.croppedImageUrl;

        switch (this.state.selectedImageNumber) {
            case 1:
                this.setState({
                    file1: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 2:
                this.setState({
                    file2: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 3:
                this.setState({
                    file3: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 4:
                this.setState({
                    file4: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 5:
                this.setState({
                    file5: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
            case 6:
                this.setState({
                    file6: croppedFileObj.blob,
                    data: dataCopy,
                    selectedImageNumber: null
                });
                break;
        }
    };

    returnImagePath = (imagePath) => {
        if (imagePath) {
            return imagePath.includes("?v=") ? this.props.location.state.services.base_url + imagePath : imagePath;
        }
        return images.cameraPhoto;
    };

    render() {
        const {isRequesting, servicesAddData} = this.props;
        const {data, selectedImageNumber} = this.state;
        const errors = servicesAddData && servicesAddData.errors ? servicesAddData.errors : {};

        const estaYears = HelperFunctions.getYears();

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Services"
                    subheading={this.isUpdate ? "Update a services for Shri Agrawal Samaj Pranyas." : "Add a new services for Shri Agrawal Samaj Pranyas."}
                    icon="pe-7s-box2 icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.services.list.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">List Services</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>{this.isUpdate ? 'Update Services' : 'Add Services'}</CardTitle>
                                    <Row form>
                                        <Col md="1" className={"row-image-container border"}>
                                            <div className={"left border-right"}>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 1)}>
                                                    <img src={this.returnImagePath(data.image_url1)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 2)}>
                                                    <img src={this.returnImagePath(data.image_url2)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 3)}>
                                                    <img src={this.returnImagePath(data.image_url3)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 4)}>
                                                    <img src={this.returnImagePath(data.image_url4)} alt=""/>
                                                </div>
                                                <div className={"item border-bottom"} onClick={this.selectFile.bind(this, 5)}>
                                                    <img src={this.returnImagePath(data.image_url5)} alt=""/>
                                                </div>
                                                <div className={"item"} onClick={this.selectFile.bind(this, 6)}>
                                                    <img src={this.returnImagePath(data.image_url6)} alt=""/>
                                                </div>

                                                {selectedImageNumber && <ImageCrop getCroppedImage={this.getCroppedImage} hideImageCrop={this.hideImageCrop}/>}
                                            </div>
                                        </Col>
                                        <Col lg="11" md={{size: 11, offset: 0}}>
                                            <Form>
                                                <Row form>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label for="name">Name of the Service</Label>
                                                            <Input type="text" name="name" id="name" bsSize="sm" placeholder="" value={data["name"]}
                                                                   invalid={HelperFunctions.isNotEmpty(errors["name"])}
                                                                   onChange={this.onInputChange.bind(this, "name")}/>
                                                            <FormFeedback>Oh noes! pick a correct name for services</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md="12">
                                                        <FormGroup className={"mb-0"}>
                                                            <Label for="description">Service Specifics</Label>
                                                            <SunEditor name="description" id="description" bsSize="sm"
                                                                       placeholder="Any Speciality, services keeper want to display on his page. Please enter here." height={300} style={{resize: 'none'}}
                                                                       defaultValue={data["description"]}
                                                                       onChange={this.handleEditorChange}/>
                                                            <FormFeedback>Oh noes! pick a correct description</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className={"justify-content-center mt-2"}>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting &&
                                            <span className="spinner-grow spinner-grow-sm mr-1"
                                                  role="status"
                                                  aria-hidden="true"/>}
                                            {isRequesting ? this.isUpdate ? "Updating...." : "Adding..." : this.isUpdate ? "Update" : "Add"}
                                        </Button>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}