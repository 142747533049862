import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import ServicesAdd from "./add";
import ServicesList from "./list";

export default class Services extends React.Component {

    list = (props) => (
        this.props.userData.permissions.samaj_notice_list ?
            <ServicesList {...props} userData={this.props.userData} getServices={this.props.getServices}
                          getCategory={this.props.getCategory}
                          groups={this.props.groups} categories={this.props.categories}
                          services={this.props.services} totalServicesCount={this.props.totalServicesCount}
                          updateServicesStatus={this.props.updateServicesStatus}
                          isRequestingForWhom={this.props.isRequestingForWhom}/>
            : <ContentMessagePopup/>
    );

    add = (props) => (
        this.props.userData.permissions.samaj_notice_add ?
            <ServicesAdd {...props} {...this.props}
                         isRequesting={this.props.isRequestingForWhom === 'samaj-seva/add'}/>
            : <ContentMessagePopup/>
    );
    update = (props) => (
        this.props.userData.permissions.samaj_notice_edit ?
            <ServicesAdd {...props} {...this.props}
                         isRequesting={this.props.isRequestingForWhom === 'samaj-seva/add'}/>
            : <ContentMessagePopup/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.services.url} render={() => (
                    <Redirect to={ProjectRoutes.services.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.services.list.url} render={this.list}/>
                <Route path={ProjectRoutes.services.add.url} render={this.add}/>
                <Route path={ProjectRoutes.services.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
