import {faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table, UncontrolledTooltip} from 'reactstrap';
import images from "../../../../../assets/images";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from "../../../../../utils/Constants";
import DateHelper from "../../../../../utils/DateHelper";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";

export default class ServicesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
            },
            currentPage: 1
        }
    }

    componentDidMount() {
        this.props.getServices(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
        window.userCollapse();
    }

    updateStatus = (services_id, status) => {
        this.props.updateServicesStatus(services_id, status, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage})
    };

    paginationClick = (pageNum) => {
        this.props.getServices(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});
        this.setState({currentPage: pageNum});
    };

    renderPagination = () => {
        const {totalServicesCount} = this.props;
        const {currentPage} = this.state;
        if (totalServicesCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalServicesCount / Constants.defaultPageSize);

            const pages = [];
            for (let i = 0; i < numPages; i++) {
                pages.push(<li key={"pg-" + i} className={`page-item mx-1 ${currentPage === (i + 1) ? "active" : ""}`} onClick={this.paginationClick.bind(this, (i + 1))}><a className="page-link py-1">{i + 1}</a></li>);
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="pt-5" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage - 1))} aria-label="Previous"><span
                            aria-hidden="true">«</span></a></li>
                        {pages}
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}><a className="page-link py-1" onClick={this.paginationClick.bind(this, (currentPage + 1))} aria-label="Next"><span
                            aria-hidden="true">»</span></a></li>
                    </ul>
                </nav>
            );
        }
        else return null;
    };

    reset = () => {
        this.setState({
            data: {
                name: '',
            }
        });
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "samaj-seva/list")) {
            this.props.getServices(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1}, "samaj-seva/search");
            this.setState({currentPage: 1});
        }
    };

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    renderFilter = () => {
        const {isRequestingForWhom} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible py-2 text-muted">Looking for something? <span className="text-success">Click here</span> to refine your search.</button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form className="pt-2">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="name">Name of the Service</Label>
                                    <Input type="text" name="name" id="name" bsSize="sm" value={data["name"]}
                                           onChange={this.onInputChange.bind(this, "name")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "samaj-seva/search") && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "samaj-seva/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const {groups, services, isRequestingForWhom} = this.props;
        const servicesLength = services ? services.length : null;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Services"
                    subheading="Available Services for Shri Agrawal Samaj Pranyas."
                    icon="pe-7s-cart icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        this.props.userData.permissions.samaj_notice_add && <Link to={ProjectRoutes.services.add.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">Add Services</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        Services List
                                    </div>
                                    {this.renderFilter()}
                                    {servicesLength > 0 && (
                                        <React.Fragment>
                                            <Table responsive striped className="mb-0">
                                                <thead>
                                                <tr>
                                                    <th>Images</th>
                                                    <th>Name</th>
                                                    <th>Added Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {services.map((services, index) => {
                                                    const obj = {};
                                                    if (HelperFunctions.isActive(services.status)) {
                                                        obj.icon = faThumbsDown;
                                                        obj.ttxt = "Make Inactive";
                                                        obj.color = "danger";
                                                        obj.statusToChange = "Inactive";
                                                    } else {
                                                        obj.icon = faThumbsUp;
                                                        obj.ttxt = "Make Active";
                                                        obj.color = "success";
                                                        obj.statusToChange = "Active";
                                                    }

                                                    return (
                                                        <tr key={"" + index}>
                                                            <td>
                                                                <img className={"shop-list-img"} width={100} src={services.image_url1 ? services.base_url + services.image_url1 : images.cameraPhoto} alt=""/>
                                                            </td>
                                                            <td>{services.name}</td>
                                                            <td>{DateHelper.getDisplayDateFromStamp(services.added_date)}</td>
                                                            <td>
                                                                <div className="row">
                                                                    {this.props.userData.permissions.samaj_notice_edit && (
                                                                        <Link to={{
                                                                            pathname: ProjectRoutes.services.update.url,
                                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                                            state: {
                                                                                services: services
                                                                            }
                                                                        }}>
                                                                            <Button className="btn-shadow mr-2 btn-sm mt-1" color="info">
                                                                                <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                            </Button>
                                                                        </Link>
                                                                    )}

                                                                    {this.props.userData.permissions.samaj_notice_active_inactive && (
                                                                        <Button id={"btn" + index}
                                                                                className="btn-shadow mr-2 btn-sm mt-1" color={obj.color}
                                                                                onClick={this.updateStatus.bind(this, services.id, obj.statusToChange)}
                                                                        >
                                                                            <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                            <UncontrolledTooltip placement="left"
                                                                                                 target={"btn" + index}>
                                                                                {obj.ttxt}
                                                                            </UncontrolledTooltip>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    )}
                                    {servicesLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No services
                                            found for selected category!</div>
                                    )}
                                    {isRequestingForWhom === 'samaj-seva/list' && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "samaj-seva/update-status" &&
                                <div className="d-flex justify-content-center align-products-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
