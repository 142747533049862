import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import ShopAdd from "./add";
import ShopList from "./list";

export default class Shop extends React.Component {

    list = (props) => (
        this.props.userData.permissions.shop_list ?
            <ShopList {...props} userData={this.props.userData} getShop={this.props.getShop}
                      getCategory={this.props.getCategory}
                      groups={this.props.groups} categories={this.props.categories}
                      shops={this.props.shops} totalShopCount={this.props.totalShopCount}
                      updateShopStatus={this.props.updateShopStatus}
                      isRequestingForWhom={this.props.isRequestingForWhom}/>
            : <ContentMessagePopup/>
    );

    add = (props) => (
        this.props.userData.permissions.shop_add ?
            <ShopAdd {...props} {...this.props}
                     isRequesting={this.props.isRequestingForWhom === 'shop/add'}/>
            : <ContentMessagePopup/>
    );
    update = (props) => (
        this.props.userData.permissions.shop_edit ?
            <ShopAdd {...props} {...this.props}
                     isRequesting={this.props.isRequestingForWhom === 'shop/add'}/>
            : <ContentMessagePopup/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.shop.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.shop.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.shop.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.shop.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.shop.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
