import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../../config/APIEndpoints";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import country from "../../area/country";
import serviceType from "../serviceType";

import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch) => {
        dispatch(country.actions.getCountry());
        dispatch(serviceType.actions.getServiceType(false));
    }
}

export function getServices(service_type_id, isEmptyFirst = true, queryData = '', isRequestingForWhom = "user-services/services/list") {
    return (dispatch) => {

        let payloadObj = {};
        if (isEmptyFirst) {
            dispatch({type: ActionTypes.GET_SERVICES, payload: {services: null}});
            payloadObj = {isRequestingForWhom};
        }

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        dispatch({
            type: ActionTypes.GET_SERVICES,
            payload: payloadObj,
            promise: APIEndpoints.getUserServices(service_type_id, queryData),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addServices(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.ADD_SERVICES,
            payload: {isRequestingForWhom: "user-services/services/add"},
            promise: APIEndpoints.addUserServices(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateServicesStatus(service_type_id, services_id = '', status = '', queryData) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_SERVICES_STATUS,
            payload: {isRequestingForWhom: "user-services/services/update-status"},
            promise: APIEndpoints.updateUserServicesStatus({service_id: services_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getServices(service_type_id, false, queryData));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
