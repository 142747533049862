import * as ActionTypes from "./actionTypes";

const initialState = {
    services: [],
    servicesAddData: {},
    totalServicesCount: 0
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_SERVICES:
            return {...state, ...action.payload};
        case ActionTypes.ADD_SERVICES:
        case ActionTypes.GET_SERVICES_ONE:
            return {...state, servicesAddData: action.payload};
        default:
            return {...state}
    }
};
export default reducer;