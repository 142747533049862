import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../../config/APIEndpoints";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import country from "../../area/country";
import group from "../group";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch) => {
        dispatch(country.actions.getCountry());
        dispatch(group.actions.getGroup(false));
    }
}

export function getShop(group_id, isEmptyFirst = true, queryData = '', isRequestingForWhom = "shop/list") {
    return (dispatch) => {

        let payloadObj = {};
        if (isEmptyFirst) {
            dispatch({type: ActionTypes.GET_SHOP, payload: {shops: null}});
            payloadObj = {isRequestingForWhom};
        }

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        dispatch({
            type: ActionTypes.GET_SHOP,
            payload: payloadObj,
            promise: APIEndpoints.getShop(group_id, queryData),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addShop(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.ADD_SHOP,
            payload: {isRequestingForWhom: "shop/add"},
            promise: APIEndpoints.addShop(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateShopStatus(group_id, shop_id = '', status = '', queryData) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_SHOP_STATUS,
            payload: {isRequestingForWhom: "shop/update-status"},
            promise: APIEndpoints.updateShopStatus({shop_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getShop(group_id, false, queryData));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
