import {faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Input, Row, Table, UncontrolledTooltip} from 'reactstrap';
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";

export default class CategoryList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGroupNumber: 0
        }
    }

    componentDidMount() {
        this.props.getCategory('');
    }

    change = (event) => {
        this.setState({selectedGroupNumber: event.target.value})
        this.props.getCategory(this.props.groups[event.target.value].id);
    };

    render() {
        const {categories, isRequestingForWhom, updateCategoryStatus, groups} = this.props;
        const categoriesLength = categories ? categories.length : null;
        const {selectedGroupNumber} = this.state;

        if (groups === null || groups.length === 0)
            return null;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Shri Agrawal Samaj Pranyas Categories"
                    subheading="Available categories for Shri Agrawal Samaj Pranyas."
                    icon="pe-7s-server icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        this.props.userData.permissions.shop_categories_add && <Link to={ProjectRoutes.sale.category.add.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">Add Category</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        Category List
                                        <div className="btn-actions-pane-right">
                                            <Input type="select" name="groups" id="groups" bsSize="sm"
                                                   onChange={this.change}>
                                                {groups && groups.length > 0 && groups.map((item, index) => {
                                                    return (
                                                        <option key={"" + index} value={index}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </div>
                                    </div>
                                    {categoriesLength > 0 && (
                                        <Table responsive striped className="mb-0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {categories.map((item, index) => {
                                                const obj = {};
                                                if (HelperFunctions.isActive(item.status)) {
                                                    obj.icon = faThumbsDown;
                                                    obj.ttxt = "Make Inactive";
                                                    obj.color = "danger";
                                                    obj.statusToChange = "Inactive";
                                                } else {
                                                    obj.icon = faThumbsUp;
                                                    obj.ttxt = "Make Active";
                                                    obj.color = "success";
                                                    obj.statusToChange = "Active";
                                                }
                                                return (
                                                    <tr key={"" + index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            <div className="row">
                                                                {this.props.userData.permissions.shop_categories_edit && (
                                                                    <Link to={{
                                                                        pathname: ProjectRoutes.sale.category.update.url,
                                                                        search: HelperFunctions.getQueryStringFromURL(),
                                                                        state: {
                                                                            id: item.id,
                                                                            group_id: item.group_id,
                                                                            name: item.name
                                                                        }
                                                                    }}>
                                                                        <Button className="btn-shadow mr-2 btn-sm" color="info">
                                                                            <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                        </Button>
                                                                    </Link>
                                                                )}
                                                                {this.props.userData.permissions.shop_categories_active_inactive && (
                                                                    <Button id={"btn" + index} className="btn-shadow mr-2 btn-sm" color={obj.color}
                                                                            onClick={updateCategoryStatus.bind(this, item.group_id, item.id, obj.statusToChange, item.name)}>
                                                                        <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                        <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                            {obj.ttxt}
                                                                        </UncontrolledTooltip>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    )}
                                    {categoriesLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No Category found for selected group!</div>
                                    )}
                                    {(isRequestingForWhom === 'sale/category/list' || categoriesLength === null) && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {(isRequestingForWhom === "sale/category/update-status") &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
