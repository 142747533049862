import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import common from "../../../common";
import user from "../../user";
import country from "../country";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import State from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class StateSmart extends React.Component {
    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <State {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[user.constants.NAME].userData,
    countries: HelperFunctions.getActiveForSelect(state[country.constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: state[constants.NAME].states,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getState: (country_id) => dispatch(actions.getState(country_id)),
        addState: (name, country_id, state_id) => dispatch(actions.addState(name, country_id, state_id)),
        updateStateStatus: (country_id, state_id, status) => dispatch(actions.updateStateStatus(country_id, state_id, status)),
    }
};

export const StateContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(StateSmart));
export default {actionTypes, actions, reducer, constants};