import HelperFunctions from "../utils/HelperFunctions";

export default class APIEndpoints {

    static isProduction = true; // if true live else dev

    static appUser_User_ID = HelperFunctions.getQueryStringValue("u_shop_id"); // based on query params in the URL we need to check if the user is from app with a valid ID
    static appUser_Admin_ID = HelperFunctions.getQueryStringValue("a_shop_id"); // based on query params in the URL we need to check if the user is from app with a valid admin ID

    /**
     * return base url for api server for PRODUCTION or dev
     * @returns {string}
     */
    static get baseURL() {
        return APIEndpoints.isProduction ? 'https://apidev.printfworld.in/agrawal-samaj-api/api/admin/' : 'http://192.168.1.101:8080/api/admin/';
    }

    static get baseURLUser() {
        return APIEndpoints.isProduction ? 'https://apidev.printfworld.in/agrawal-samaj-api/api/' : 'http://192.168.1.101:8080/api/';
    }

    /**
     * following method is used to remove double slashes from url if present
     * @param url
     * @returns {*}
     */
    static removeDoubleSlashes(url) {
        url = url.replace(new RegExp('//', 'g'), '/');
        if (url.charAt(url.length - 1) === '/') {
            url = url.slice(0, -1);
        }
        return url;
    }

    /**
     * return headers to pass while calling an API works for both PRODUCTION and STAGING
     * @returns {string}
     */
    static headers(whatIsContent = null) {
        // let userId = HelperFunctions.readCookie('user_id');
        let userId = APIEndpoints.appUser_User_ID ? APIEndpoints.appUser_Admin_ID : localStorage.getItem('user_id');
        if (userId === undefined) userId = '';
        let header = {
            'user_id': userId,
            'user_type': 'admin',
            'Accept': 'application/json'
        };
        if (whatIsContent === 1) {
            header['Content-Type'] = 'application/json';
        } else if (whatIsContent === 2) {
            header['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return header;
    }

    /******************************************************User APIs Start************************************************************/
    static login(data) {
        return {
            url: `${APIEndpoints.baseURL}employee/login`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static checkLogin() {
        return {
            url: `${APIEndpoints.baseURL}employee/check`,
            method: 'GET',
            headers: APIEndpoints.headers(), // for content type url encoded
        };
    }

    static forgotPassword(data) {
        return {
            url: `${APIEndpoints.baseURL}employee/forgot-password`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static matchOTP(data) {
        return {
            url: `${APIEndpoints.baseURL}employee/match-otp`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static newPassword(data) {
        return {
            url: `${APIEndpoints.baseURL}employee/change-password`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    /******************************************************User APIs End************************************************************/
    /******************************************************Customer APIs Start************************************************************/

    static getCustomer(query = '') {
        return {
            url: `${APIEndpoints.baseURLUser}user/${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static updateCustomerStatus(data) {
        return {
            url: `${APIEndpoints.baseURLUser}user/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static updateOrdersStatus(data) {
        return {
            url: `${APIEndpoints.baseURLUser}user/orders/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static orders(queryData) {
        return {
            url: `${APIEndpoints.baseURLUser}user/orders${queryData}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static dashStats() {
        return {
            url: `${APIEndpoints.baseURLUser}user/dashboard/stats`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    /******************************************************Customer APIs End************************************************************/

    /******************************************************Country APIs Start************************************************************/
    static getCountry() {
        return {
            url: `${APIEndpoints.baseURL}area/country`,
            method: 'GET',
            headers: APIEndpoints.headers(2),
        }
    }

    static addCountry(data) {
        return {
            url: `${APIEndpoints.baseURL}area/country`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static updateCountryStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}area/country/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************Country APIs End************************************************************/

    /******************************************************State APIs Start************************************************************/
    static getState(country_id = '') {
        return {
            url: `${APIEndpoints.baseURL}area/state/${country_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(2),
        }
    }

    static addState(data) {
        return {
            url: `${APIEndpoints.baseURL}area/state`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static updateStateStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}area/state/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************State APIs End************************************************************/
    /******************************************************City APIs Starts************************************************************/
    static getCity(country_id = '', state_id = '', city) {
        return {
            url: `${APIEndpoints.baseURL}area/city/${country_id}/${state_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(2),
        }
    }

    static addCity(data) {
        return {
            url: `${APIEndpoints.baseURL}area/city`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static updateCityStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}area/city/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************City APIs Ends************************************************************/

    /******************************************************Designation APIs Start************************************************************/
    static getDesignation() {
        return {
            url: `${APIEndpoints.baseURL}designation`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addDesignation(data) {
        return {
            url: `${APIEndpoints.baseURL}designation`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateDesignationStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}designation/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Designation APIs End************************************************************/

    /******************************************************Department APIs Start************************************************************/
    static getDepartment() {
        return {
            url: `${APIEndpoints.baseURL}department`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addDepartment(data) {
        return {
            url: `${APIEndpoints.baseURL}department`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateDepartmentStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}department/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Department APIs End************************************************************/
    /******************************************************Employee APIs Start************************************************************/
    static getEmployee(id = '') {
        return {
            url: `${APIEndpoints.baseURL}employee/${id}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addEmployee(data, emp_id = '') {
        return {
            url: `${APIEndpoints.baseURL}employee/${emp_id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateEmployeeAddress(data, id) {
        return {
            url: `${APIEndpoints.baseURL}employee/address/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }
    static updateEmployeePermissions(data, id) {
        return {
            url: `${APIEndpoints.baseURL}employee/permissions/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateEmployeeEmergencycontact(data, id) {
        return {
            url: `${APIEndpoints.baseURL}employee/emergency-contact/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateEmployeeImage(data) {
        console.log("in API", data)
        return {
            url: `${APIEndpoints.baseURL}employee/image-upload`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateEmployeeDocuments(data) {
        return {
            url: `${APIEndpoints.baseURL}employee/documents-upload`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateEmployeeStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}employee/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Employee APIs End************************************************************/

    /******************************************************Group APIs Start************************************************************/
    static getGroup() {
        return {
            url: `${APIEndpoints.baseURL}sale/group`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addGroup(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/group`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateGroupStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/group/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Group APIs End************************************************************/

    /******************************************************Category APIs Start************************************************************/
    static getCategory(group_id) {
        return {
            url: `${APIEndpoints.baseURL}sale/category/${group_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addCategory(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/category`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateCategoryStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/category/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Category APIs End************************************************************/

    /******************************************************Product Type APIs Start************************************************************/
    static getProductType(group_id = '', category_id = '') {
        const url = APIEndpoints.removeDoubleSlashes(`sale/product-type/${group_id}/${category_id}`);
        return {
            url: `${APIEndpoints.baseURL}${url}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addProductType(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/product-type`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateProductTypeStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/product-type/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Product Type APIs End************************************************************/

    /******************************************************Product APIs Start************************************************************/
    static getProduct(group_id = '', category_id = '', query = '') {
        const url1 = APIEndpoints.removeDoubleSlashes(`${group_id}/${category_id}`);
        return {
            url: `${APIEndpoints.baseURL}sale/product/${url1}${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addProduct(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/product/add`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateProductStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/product/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static updateProductTrendingStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/product/update-trending-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    static updateProductNewStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/product/update-new-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************Product APIs End************************************************************/

    /******************************************************Shop APIs Start************************************************************/
    static getShop(group_id = '', query = '') {
        const url1 = APIEndpoints.removeDoubleSlashes(`${group_id}`);
        return {
            url: `${APIEndpoints.baseURL}sale/shop/${url1}${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addShop(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/shop/add`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateShopStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/shop/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************Shop APIs End************************************************************/

    /******************************************************Services APIs Start************************************************************/
    static getServices(query = '') {
        return {
            url: `${APIEndpoints.baseURL}services/${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addServices(data) {
        return {
            url: `${APIEndpoints.baseURL}services/add`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateServicesStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}services/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************Services APIs End************************************************************/

    /******************************************************Service Provider APIs Start************************************************************/
    static getProvider(id = '') {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/${id}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addProvider(data, emp_id = '') {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/${emp_id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateProviderAddress(data, id) {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/address/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateProviderBusiness(data, id) {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/business/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateProviderStatusory(data, id) {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/statutory/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateProviderBankDetail(data, id) {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/bank-detail/${id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateProviderDocuments(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/documents-upload`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateProviderStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}sale/provider/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************Service Provider APIs End************************************************************/

    /******************************************************Service Type APIs Start************************************************************/
    static getServiceType() {
        return {
            url: `${APIEndpoints.baseURL}user-services/service-type`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addServiceType(data) {
        return {
            url: `${APIEndpoints.baseURL}user-services/service-type`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static updateServiceTypeStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}user-services/service-type/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    /******************************************************Service Type End************************************************************/

    /******************************************************User Services APIs Start************************************************************/
    static getUserServices(service_type_id = '', query = '') {
        const url1 = APIEndpoints.removeDoubleSlashes(`${service_type_id}`);
        return {
            url: `${APIEndpoints.baseURL}user-services/services/${url1}${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static addUserServices(data) {
        return {
            url: `${APIEndpoints.baseURL}user-services/services/add`,
            method: 'POST',
            headers: APIEndpoints.headers(),
            data: data,
        }
    }

    static updateUserServicesStatus(data) {
        return {
            url: `${APIEndpoints.baseURL}user-services/services/update-status`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        }
    }

    /******************************************************User Services APIs End************************************************************/
}