import * as ActionTypes from "./actionTypes";

const initialState = {
    employees: null,
    employeeAddData: {},
    employeeAddressData: {},
    employeePermissionsData: {},
    employeeEmergencyContactData: {},
    employeeImageData: {},
    employeeDocumentsData: {},
    selectedTabKey: 0,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_EMPLOYEE:
            return {...state, employees: action.payload};
        case ActionTypes.ADD_EMPLOYEE_BASIC_INFO:
        case ActionTypes.GET_EMPLOYEE_ONE:
            return {...state, employeeAddData: action.payload};
        case ActionTypes.UPDATE_EMPLOYEE_ADDRESS:
            return {...state, employeeAddressData: action.payload};
        case ActionTypes.UPDATE_EMPLOYEE_PERMISSSIONS:
            return {...state, employeePermissionsData: action.payload};
        case ActionTypes.UPDATE_EMPLOYEE_EMERGENCY_CONTACT:
            return {...state, employeeEmergencyContactData: action.payload};
        case ActionTypes.UPDATE_EMPLOYEE_IMAGE:
            return {...state, employeeImageData: action.payload};
        case ActionTypes.UPDATE_EMPLOYEE_DOCUMENTS:
            return {...state, employeeDocumentsData: action.payload};
        case ActionTypes.CHANGE_TAB:
            return {...state, ...action.payload};
        default:
            return {...state}
    }
};
export default reducer;