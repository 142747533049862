import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import common from "../../../common";
import category from "../category";
import group from "../group";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import ProductType from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class ProductTypeSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <ProductType {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    productTypes: state[constants.NAME].productTypes,
    groups: HelperFunctions.getActiveForSelect(state[group.constants.NAME].groups, {name: "--Select Group--", id: ''}),
    categories: HelperFunctions.getActiveForSelect(state[category.constants.NAME].categories, {name: "--Select Category--", id: ''}),
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getCategory: (group_id) => dispatch(category.actions.getCategory(group_id)),
        getProductType: (group_id, category_id) => dispatch(actions.getProductType(group_id, category_id)),
        addProductType: (name, group_id, category_id, id) => dispatch(actions.addProductType(name, group_id, category_id, id)),
        updateProductTypeStatus: (group_id, category_id, id, status, name) => dispatch(actions.updateProductTypeStatus(group_id, category_id, id, status, name)),
    }
};

export const ProductTypeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductTypeSmart));
export default {actionTypes, actions, reducer, constants};