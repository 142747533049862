import React from "react";
import {Redirect} from "react-router-dom";
import ErrorContainer from "../../commons/components/errorContainer/index";
import HelperFunctions from "../../utils/HelperFunctions";
import ProjectRoutes from "./projectRoutes";
import {UserContainer} from "../../redux/modules/screens/user";
import {DashboardContainer} from "../../redux/modules/screens/dashboard";
import {CountryContainer} from "../../redux/modules/screens/area/country";
import {StateContainer} from "../../redux/modules/screens/area/state";
import {CityContainer} from "../../redux/modules/screens/area/city";
import {EmployeeContainer} from "../../redux/modules/screens/employee";
import {CustomerContainer} from "../../redux/modules/screens/customer";
import {DesignationContainer} from "../../redux/modules/screens/designation";
import {DepartmentContainer} from "../../redux/modules/screens/department";
import {GroupContainer} from "../../redux/modules/screens/sale/group";
import {CategoryContainer} from "../../redux/modules/screens/sale/category";
import {ProductTypeContainer} from "../../redux/modules/screens/sale/productType";
import {ProductContainer} from "../../redux/modules/screens/sale/product";
import {ShopContainer} from "../../redux/modules/screens/sale/shop";
import {ServicesContainer} from "../../redux/modules/screens/services";
import {ServiceTypeContainer} from "../../redux/modules/screens/userServices/serviceType";
import {ServicesContainer as UserServicesContainer} from "../../redux/modules/screens/userServices/services";

const routes = [
    {
        path: ProjectRoutes.base.url,
        exact: true,
        render: () => (
            <Redirect to={ProjectRoutes.user.url + HelperFunctions.getQueryStringFromURL()}/>
        )
    },
    {
        path: ProjectRoutes.user.url,
        component: UserContainer,
    },
    {
        path: ProjectRoutes.dashboard.url,
        component: DashboardContainer,
        exact: true
    },
    {
        path: ProjectRoutes.area.country.url,
        component: CountryContainer,
    },
    {
        path: ProjectRoutes.area.state.url,
        component: StateContainer,
    },
    {
        path: ProjectRoutes.area.city.url,
        component: CityContainer,
    },
    {
        path: ProjectRoutes.employee.url,
        component: EmployeeContainer,
    },
    {
        path: ProjectRoutes.customer.url,
        component: CustomerContainer,
    },
    {
        path: ProjectRoutes.designation.url,
        component: DesignationContainer,
    },
    {
        path: ProjectRoutes.department.url,
        component: DepartmentContainer,
    },
    {
        path: ProjectRoutes.sale.group.url,
        component: GroupContainer,
    },
    {
        path: ProjectRoutes.sale.category.url,
        component: CategoryContainer,
    },
    {
        path: ProjectRoutes.sale.productType.url,
        component: ProductTypeContainer,
    },
    {
        path: ProjectRoutes.sale.product.url,
        component: ProductContainer,
    },
    {
        path: ProjectRoutes.sale.shop.url,
        component: ShopContainer,
    },
    {
        path: ProjectRoutes.services.url,
        component: ServicesContainer,
    },
    {
        path: ProjectRoutes.userServices.serviceType.url,
        component: ServiceTypeContainer,
    },
    {
        path: ProjectRoutes.userServices.services.url,
        component: UserServicesContainer,
    },
    {
        component: ErrorContainer,
    },

];

export default routes;