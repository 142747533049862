import cameraPhoto from "./utils/images/photo-camera.png";
import defualtDoc from "./utils/images/default-document.jpg";
import abstract1 from "./utils/images/originals/abstract.jpg";
import logo from "./utils/images/ag-logo.png";
const images = function () {
    return {
        cameraPhoto,
        abstract1, defualtDoc,
        logo
    };
}();

export default images;