import React, {Fragment} from "react";
import {Redirect, Route} from "react-router-dom";
import ContentMessagePopup from "../../../../../commons/components/contentMessage";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import CustomerList from "./list";
import Orders from "./orders";

export default class Customer extends React.Component {

    list = (props) => (
        this.props.userData.permissions.app_user_list ?
            <CustomerList {...props} {...this.props}/>
            : <ContentMessagePopup/>
    );
    order = (props) => (
        <Orders {...props} {...this.props}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.customer.url} render={() => (
                    <Redirect to={ProjectRoutes.customer.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.customer.list.url} render={this.list}/>
                <Route path={ProjectRoutes.customer.order.url} render={this.order}/>
            </Fragment>
        )
    }
}
